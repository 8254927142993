import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { isFunction } from "lodash";
import GridPerspectiveFloor from "src/components/Common/GridPerspectiveFloor";
import JoinRoomForm from "src/components/JoinRoomFormV2";
import { templateTypes } from "src/constants";
import { AppAnalytics, flattenObject, history } from "src/helpers";
import UseCaseForm from "src/modules/onboarding/components/Forms/UseCaseForm";
import UseCaseQuestionForm, {
  LiveStreamingAddOnForm,
  SetTemplateStepsConfigurationForm,
} from "src/modules/onboarding/components/Forms/UseCaseQuestionForm";
import FormContent from "src/modules/onboarding/components/layout/FormContent";
import FormSidebar from "src/modules/onboarding/components/layout/FormSidebar";
import { addonConfig } from "src/modules/onboarding/types/form";
import { cmsTemplateType } from "src/types/cmsTypes";
import { policyType, roleTemplatePolicy } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { currentUser, saveCurrentUser, signedUpUser } from "src/utils";
import {
  AddCircleIcon,
  DoorIcon,
  InfoIcon,
  LayoutIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";
import { isUseCaseCMSTemplate } from "../../../onboarding/helpers";
import masterTemplateToAnswerConfig from "../../../onboarding/helpers/answerToTemplate";
import { ConfigMap } from "../../../onboarding/interface";
import { steps } from "../../containers/setTemplateFlowWithLayout";
import DeployForm from "../Forms/DeployForm";
import "src/components/Common/tooltipReactstrap.css";

type Props = {
  isImportedTemplate: boolean;
  publicTemplates: cmsTemplateType[];
  setTemplateFlow: (templateId: null | number) => void;
  id: null | number;
  policyType: string;
  stepAnswers: {};
  setStepAnswers: (stepAns: {}) => void;
  setSubdomain: (subdomain: string) => void;
  roomName: string;
  setTemplateIdInStore: (id: null | number) => void;
  setTemplateName: (name: string) => void;
  setTemplateInStore: ({
    policy,
    checkPolicyName,
  }: {
    policy?: policyType | undefined;
    checkPolicyName?: boolean;
  }) => void;
  policy:
    | {
        name: string;
        roles: Record<string, roleTemplatePolicy>;
      }
    | policyType;
  subdomain: string;
  cmsRoleLayouts: RoleLayouts;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
  setAppDetails: (
    appDetails:
      | {
          tile_shape?: string | undefined;
          metadata?: string | undefined;
        }
      | {}
  ) => void;
  largeRoom: boolean;
  setLargeRoom: (bool: boolean) => void;
  createTemplateFromStore: (isOnboarding: boolean) => void;
  roomId: string;
  config: addonConfig[];
  addOns: addonConfig[];
  isRoomNameValid: boolean;
  activeStep: string;
  setActiveStep: (str: string) => void;
  createdTemplate: {};
  checkTemplateNameStatus: string;
  subdomainChecking: boolean;
  subdomainError: string;
  templateNameError: string;
  isTemplateConfigValid: boolean;
  autoGenerateSubdomain: () => void;
  autoGenerateTemplate: () => void;
  importTemplate: () => void;
};

const iconStyle = { color: "inherit", height: "32px", width: "32px" };
function SetTemplateSteps({
  autoGenerateSubdomain,
  autoGenerateTemplate,
  setTemplateName,
  policy,
  subdomain,
  roomName,
  isImportedTemplate = false,
  isTemplateConfigValid,
  publicTemplates,
  setTemplateInStore,
  cmsRoleLayouts,
  setRoleLayouts,
  setAppDetails,
  importTemplate,
  setTemplateIdInStore,
  roomId,
  config,
  addOns,
  largeRoom,
  setLargeRoom,
  policyType,
  setTemplateFlow,
  id,
  isRoomNameValid,
  setActiveStep,
  activeStep,
  setSubdomain,
  setStepAnswers,
  stepAnswers,
  createTemplateFromStore,
  createdTemplate,
  checkTemplateNameStatus,
  subdomainChecking,
  subdomainError,
  templateNameError,
}: Props) {
  const [openImportTemplateTooltip, setOpenImportTemplateTooltip] =
    useState(false);
  const stepsConfig: ConfigMap = {
    CONFIGURE_ROOM: {
      id: 0,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Pick your use-case",
          subtitle: "Select your use-case, and we’ll help you configure it",
          topRightComp: (
            <Flex direction="row" gap={2}>
              <Button
                onClick={() => {
                  importTemplate();
                }}
                icon
                variant="standard"
                css={{ p: "$4", r: "$0" }}
              >
                <AddCircleIcon />

                <Text variant="sub2" css={{ mr: "$4" }}>
                  Import Template
                </Text>
              </Button>
              <Flex justify="center" align="center" id="import-template">
                <InfoIcon />
              </Flex>
              <Tooltip
                autohide={false}
                className="mytooltip"
                placement="top"
                target="import-template"
                isOpen={openImportTemplateTooltip}
                toggle={() =>
                  setOpenImportTemplateTooltip(!openImportTemplateTooltip)
                }
              >
                <Flex direction="column" justify="center" align="start">
                  <Text variant="caption" css={{ c: "$on_surface_high" }}>
                    Import a template into your current workspace
                  </Text>
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/100ms-dashboard/export-import-template`}
                  >
                    <Text
                      variant="caption"
                      css={{ c: "$primary_bright" }}
                      as="span"
                    >
                      View guide to import/export templates
                    </Text>
                  </a>
                </Flex>
              </Tooltip>
            </Flex>
          ),
        },
        innerContent: (
          <UseCaseForm
            publicTemplates={publicTemplates}
            setTemplateFlow={setTemplateFlow}
          />
        ),
        footer: {
          disablePrimary: id === null || !isRoomNameValid,

          primaryCTA: {
            text: "Configure",
            onClick: () => {
              AppAnalytics.track("btn.clicked", {
                btnId: "configure",
                componentId: "guided.onboarding",
                value: "step.1",
                policyType: policyType,
              });

              if (isUseCaseCMSTemplate(policyType)) {
                const configAnswers = config.reduce((acc, c) => {
                  return { ...acc, [c.key]: c.defaultValue };
                }, {});
                const addOnAnswers = addOns.reduce((acc, c) => {
                  return { ...acc, [c.key]: c.defaultValue };
                }, {});

                setStepAnswers({
                  [steps.CONFIGURE_USE_CASE.key]: configAnswers,
                  [steps.SELECT_ADD_ON.key]: addOnAnswers,
                });
                setActiveStep(steps.CONFIGURE_USE_CASE.key);
              } else {
                setActiveStep(steps.DEPLOY.key);
              }
            },
          },
        },
      },
    },
    CONFIGURE_USE_CASE: {
      id: 1,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Configure",
          subtitle:
            "Select features and configuration you require for your use-case",
        },
        innerContent:
          policyType !== templateTypes.LIVE_STREAM ? (
            <UseCaseQuestionForm
              step={steps.CONFIGURE_USE_CASE.key}
              configs={config}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                setStepAnswers({ ...stepAns })
              }
            />
          ) : (
            <SetTemplateStepsConfigurationForm
              css={{ maxHeight: "456px" }}
              step={steps.CONFIGURE_USE_CASE.key}
              configs={config}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                setStepAnswers({ ...stepAns })
              }
            />
          ),
        footer: {
          disablePrimary: false,
          text: "Not able to choose? You can always change your configuration later",
          secondaryCTA: {
            text: "Go Back",
            onClick: () => {
              setActiveStep(steps.CONFIGURE_ROOM.key);
              setTemplateIdInStore(null);
              setTemplateInStore({});
            },
          },
          primaryCTA: {
            text: "Select Add-ons",
            onClick: () => {
              AppAnalytics.track("btn.clicked", {
                btnId: "select.add.ons",
                componentId: "guided.onboarding",
                value: "step.2",
                policyType: policyType,
              });
              setActiveStep(steps.SELECT_ADD_ON.key);
            },
          },
        },
      },
    },

    SELECT_ADD_ON: {
      id: 2,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Select Add-ons",
          subtitle: "Pick additional features you require for your use-case",
        },
        innerContent:
          policyType !== templateTypes.LIVE_STREAM ? (
            <LiveStreamingAddOnForm
              step={steps.SELECT_ADD_ON.key}
              configs={addOns.filter(addOn => addOn.key !== "largeRoom")}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                setStepAnswers({ ...stepAns })
              }
            />
          ) : (
            <SetTemplateStepsConfigurationForm
              css={{ maxHeight: "456px" }}
              key="SELECT_ADD_ON"
              step={steps.SELECT_ADD_ON.key}
              configs={addOns.filter(addOn => addOn.key !== "largeRoom")}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                setStepAnswers({ ...stepAns })
              }
            />
          ),
        footer: {
          disablePrimary: false,
          text: "Not able to choose? You can always change your configuration later",
          secondaryCTA: {
            text: "Go Back",
            onClick: () => {
              setActiveStep(steps.CONFIGURE_USE_CASE.key);
            },
          },

          primaryCTA: {
            text: "Deploy",
            onClick: () => {
              AppAnalytics.track("btn.clicked", {
                btnId: "deploy",
                componentId: "guided.onboarding.deploy",
                value: "step.3",
                policyType: policyType,
              });

              setActiveStep(steps.DEPLOY.key);
            },
          },
        },
      },
    },
    DEPLOY: {
      id: 3,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Deploy",
          subtitle: "Enter template name and subdomain to deploy your use-case",
        },
        innerContent: (
          <DeployForm
            largeRoom={largeRoom}
            setLargeRoom={setLargeRoom}
            templateName={policy?.name}
            checkTemplateNameStatus={checkTemplateNameStatus}
            setTemplateName={setTemplateName}
            subdomain={subdomain}
            autoGenerateSubdomain={autoGenerateSubdomain}
            autoGenerateTemplate={autoGenerateTemplate}
            setSubdomain={subdomain => {
              setSubdomain(subdomain);
            }}
            subdomainChecking={subdomainChecking}
            subdomainError={subdomainError}
            templateNameError={templateNameError}
          />
        ),
        footer: {
          disablePrimary: !isTemplateConfigValid,
          text: "Not able to choose? You can always change your configuration later",
          secondaryCTA: {
            text: "Go Back",
            onClick: () => {
              if (isUseCaseCMSTemplate(policyType) && !isImportedTemplate) {
                setActiveStep(steps.SELECT_ADD_ON.key);
              } else {
                setActiveStep(steps.CONFIGURE_ROOM.key);
                setTemplateIdInStore(null);
                setTemplateInStore({
                  policy: {} as policyType,
                  checkPolicyName: false,
                });
              }
            },
          },

          primaryCTA: {
            text: "Experience",
            onClick: () => {
              const selectionAnalyticsData = flattenObject({
                selection: stepAnswers,
              });
              AppAnalytics.track("btn.clicked", {
                btnId: "experience",
                componentId: "guided.onboarding.select-add-ons",
                value: "step.4",
                policyType: policyType,
                ...selectionAnalyticsData,
              });

              const tempPolicy = { ...policy };
              const tempRoleLayouts = { ...cmsRoleLayouts };
              if (
                isFunction(
                  masterTemplateToAnswerConfig?.[
                    policyType as keyof typeof masterTemplateToAnswerConfig
                  ]?.function
                ) &&
                !isImportedTemplate
              ) {
                masterTemplateToAnswerConfig[
                  policyType as keyof typeof masterTemplateToAnswerConfig
                ].function({
                  stepAnswers: stepAnswers,
                  policy: tempPolicy as policyType,
                  roleLayouts: tempRoleLayouts,
                  setPolicy: (policy: policyType | undefined) => {
                    setTemplateInStore({ policy, checkPolicyName: false });
                  },
                  setRoleLayouts: (roleLayouts: RoleLayouts) => {
                    setRoleLayouts(roleLayouts);
                  },
                  setAppDetails: (
                    appDetails:
                      | {}
                      | {
                          tile_shape?: string | undefined;
                          metadata?: string | undefined;
                        }
                  ) => {
                    setAppDetails(appDetails);
                  },
                });
              }
              createTemplateFromStore(false);
              setActiveStep(steps.JOIN_ROOM.key);
            },
          },
        },
      },
    },
    JOIN_ROOM: {
      id: 4,
      content: {
        header: {
          icon: <DoorIcon style={iconStyle} />,
          title: "Experience",
          subtitle:
            "Select a role and choose the way you want to join your room",
          topRightComp: (
            <Button
              variant="standard"
              css={{ r: "$0" }}
              onClick={() => {
                if (signedUpUser()) {
                  const user = currentUser();
                  const tempUser = {
                    ...user,
                    show_onboarding: !user.show_onboarding,
                  };
                  saveCurrentUser(tempUser);
                }
                AppAnalytics.track("btn.clicked", {
                  btnId: "go.to.dashboard",
                  componentId: "guided.onboarding.customize",
                });
                history.push("/dashboard");
              }}
            >
              <Text variant="sub2">Go to Dashboard</Text>
            </Button>
          ),
        },
        innerContent: (
          <Box
            css={{
              h: "calc(100vh - 380px)",
              overflowY: "auto",
              w: "100%",
              maxHeight: "432px",
            }}
          >
            <JoinRoomForm
              roomId={roomId}
              componentId="guided.onboarding"
              // @ts-ignore FIXME: fix the code flow for created template
              subdomain={createdTemplate.subdomain}
              // @ts-ignore FIXME: fix the code flow for created template
              templateId={createdTemplate.id}
              fetchTemplates={true}
            />
          </Box>
        ),
      },
    },
  };
  return (
    <Flex
      direction="column"
      align="center"
      justify="start"
      css={{
        mx: "auto",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Flex
        css={{
          position: "fixed",
          w: "41rem",
          h: "41rem",
          r: "41rem",
          left: "-10%",
          top: "70%",
          opacity: "0.6",
          background: "rgba(38, 114, 237, 0.24)",
          filter: "blur(250px)",
          zIndex: "0",
        }}
      />
      <Flex
        css={{
          position: "fixed",
          w: "20.25rem",
          h: "20.25rem",
          r: "20.25rem",
          right: "10%",
          bottom: "20%",
          opacity: "0.3",
          background: "#26BDED",
          filter: "blur(300px)",
          zIndex: "0",
        }}
      />
      <Flex css={{ position: "fixed", w: "100%", height: "100%", bottom: 0 }}>
        <GridPerspectiveFloor />
      </Flex>
      <Flex direction="column">
        <Flex justify="start" direction="column">
          <Text variant="h4" css={{ color: "$on_surface_high" }}>
            Create a new template
          </Text>

          <Text css={{ color: "$on_surface_medium", mt: "$xs" }}>
            Get Started With Live Video by Building Your Use-Case
          </Text>
        </Flex>
        <Flex align="start" css={{ my: "$16", gap: "$16" }}>
          <FormSidebar
            steps={steps}
            activeStepIndex={stepsConfig?.[activeStep]?.id}
            infoTitle={stepsConfig[activeStep]?.infoTitle}
            infoBody={stepsConfig[activeStep]?.infoBody}
            infoLink={stepsConfig[activeStep]?.infoLink}
            roomName={roomName}
            templateName={policy.name}
            subdomain={subdomain}
          />
          <FormContent
            {...stepsConfig[activeStep]?.content}
            activeStep={activeStep}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SetTemplateSteps;
