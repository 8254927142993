import React from "react";
import { capitalize } from "lodash";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import { CSS, Dropdown, Flex, Text } from "@100mslive/roomkit-react";
import TitleWithTrend from "../../common/TitleWithTrend";

type Props = {
  groupBy: string;
  setGroupBy: (groupBy: string) => void;
  groupByOptions: string[];
  total: number;
  trend: number;
  tooltip: string;
  title: string;
  showTotal?: boolean;
  showTrend?: boolean;
  css?: CSS;
  makeTotalOpaque?: boolean;
  totalLabel?: string;
};

export default function activePeersChartHeader({
  groupBy,
  setGroupBy,
  groupByOptions,
  total,
  tooltip,
  title,
  trend,
  css,
  showTotal = true,
  showTrend = true,
  makeTotalOpaque = true,
  totalLabel,
}: Props) {
  return (
    <Flex justify="between" align="center" css={{ w: "100%", ...css }}>
      <Flex direction="column">
        <TitleWithTrend
          title={title}
          tooltip={tooltip}
          total={total}
          trend={trend}
          totalLabel={totalLabel}
          showTotal={showTotal}
          showTrend={showTrend}
          makeTotalOpaque={makeTotalOpaque}
        />
        {/* Trend */}
      </Flex>
      <Flex align="center">
        <Text
          variant="caption"
          css={{ mr: "$4", fontWeight: "$regular", c: "$on_surface_low" }}
        >
          Group By
        </Text>
        <ConfigSingleSelect
          inputText={capitalize(groupBy) || "Please select Group By"}
          inputTextCss={{
            fontWeight: "$regular",
            fontSize: "$xs",
            width: "50px",
            textAlign: "left",
            mr: "$2",
          }}
          content={
            <>
              {groupByOptions.map((option: string) => (
                <Dropdown.Item
                  key={option}
                  css={{ p: "$4 $8" }}
                  onClick={() => {
                    setGroupBy(option);
                  }}
                >
                  <Text variant="caption">{capitalize(option)}</Text>
                </Dropdown.Item>
              ))}
            </>
          }
        />
      </Flex>
    </Flex>
  );
}
