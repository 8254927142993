import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { fetchPeersAnalyticsIssueDetails } from "src/store/analytics/actions";
import { RootState } from "src/store/reducers";
import {
  AlertTriangleIcon,
  ShieldIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import PeerIssueLogs from "../components/peerIssueLogs";
import "react-loading-skeleton/dist/skeleton.css";

const infoFlexCss = {
  borderTopRightRadius: "$1",
  borderBottomRightRadius: "$1",
  flex: "1 1 0",
  flexWrap: "wrap",
  w: "100%",
  borderBottom: "$space$px solid $border_default",
  borderTop: "$space$px solid $border_default",
  borderRight: "$space$px solid $border_default",
};
type Props = { peerId: string };

function PeerIssues({ peerId }: Props) {
  const dispatch = useDispatch();
  const { fetchPeerAnalyticsIssueDetailsStatus, peerIssueDetails } =
    useSelector((state: RootState) => state.roomDebugger);

  const noIssuesPresent = useMemo(() => {
    if (isEmpty(peerIssueDetails)) {
      return true;
    }
    //check for each key in object the array is empty or not
    return Object.keys(peerIssueDetails).every(key =>
      isEmpty(peerIssueDetails?.[key as keyof typeof peerIssueDetails] || [])
    );
  }, [peerIssueDetails]);

  useEffect(() => {
    if (peerId) {
      dispatch(fetchPeersAnalyticsIssueDetails(peerId));
    }
  }, [peerId, dispatch]);

  return (
    <>
      {fetchPeerAnalyticsIssueDetailsStatus !== API_CALL_STATE.FAILED ? (
        <Flex css={{ w: "100%", py: "$10" }}>
          <Flex
            direction="column"
            justify="between"
            css={{ ...infoFlexCss, py: "$8" }}
          >
            <Flex justify="between" align="center" css={{ px: "$10" }}>
              <Text variant="sub2" css={{ fontWeight: 600 }}>
                Experience Issues
              </Text>
            </Flex>
            {fetchPeerAnalyticsIssueDetailsStatus ===
            API_CALL_STATE.IN_PROGRESS ? (
              <Flex css={{ w: "100%", px: "$12", py: "$10" }}>
                <Skeleton containerClassName="flex-1" height={240} />
              </Flex>
            ) : !noIssuesPresent ? (
              <PeerIssueLogs data={peerIssueDetails} />
            ) : (
              <Flex
                direction="column"
                css={{
                  rowGap: "$md",
                  minHeight: "240px",
                  w: "100%",
                  textAlign: "center",
                }}
                justify="center"
                align="center"
              >
                <Flex css={{ c: "$on_surface_low" }}>
                  <ShieldIcon width={40} height={40} />
                </Flex>
                <Flex direction="column">
                  <Text variant="sub2" css={{ mb: "$2" }}>
                    No Issues
                  </Text>
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$on_surface_medium",
                      maxWidth: "20rem",
                    }}
                  >
                    This peer had no experience issues throughout the session.
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex css={{ w: "100%", py: "$10", textAlign: "center" }}>
          <Flex css={{ ...infoFlexCss, py: "$8", px: "$10" }}>
            <Flex justify="between" align="center" css={{ w: "100%" }}>
              <Text variant="sub2" css={{ fontWeight: 600 }}>
                Experience Issues
              </Text>
            </Flex>
            <Flex
              css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              justify="center"
              align="center"
              direction="column"
            >
              <Flex css={{ c: "$alert_error_default" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column">
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    c: "$on_surface_medium",
                    w: "20rem",
                  }}
                >
                  We couldn&apos;t load details for this chart. This feature is
                  currently in alpha and there are known issues we&apos;re
                  working on.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default PeerIssues;
