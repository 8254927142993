import React from "react";
import { EChartsOption } from "echarts";
import { isEmpty } from "lodash";
import { default as EchartsWrapper } from "src/components/Echarts";
import { Flex } from "@100mslive/roomkit-react";

type echartsOptions = EChartsOption;
export const RecordingsFailureRateChart = ({
  data,
  loading,
  filter,
}: {
  filter: string;
  data: {
    time: string;
    ratio: number;
  }[];
  loading: boolean;
}) => {
  if (isEmpty(data)) {
    return <></>;
  }
  const isDaily = filter === "daily";
  const eventLogChartoptions: echartsOptions = {
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: "axis", // Trigger tooltip on item (icon) hover
      backgroundColor: "#1E232A",
      borderRadius: 4,
      padding: 4,
      borderColor: "#1E232A",
      textStyle: {
        color: "#F5F9FF",
      },
      show: true,
      valueFormatter: value => `${value} %`,
    },
    xAxis: {
      type: "time",
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
      axisLabel: {
        formatter: function (value: number, index: number) {
          return isDaily
            ? "{MMM} {dd}"
            : (index - 1) % 7 === 0
            ? //@ts-ignore
              formatWeekRange(new Date(value).toString())
            : "";
        },
        showMaxLabel: true,
        showMinLabel: true,
        alignMaxLabel: isDaily ? "center" : "right",
      },
    },

    grid: {
      top: "10%",
      left: "5%",
      right: "5%",
      bottom: "10%",
    },

    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value} %",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
      min: 0,
    },
    series: {
      name: "Recordings Failure Ratio",
      type: "line",
      data: data
        .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
        .map(item => {
          return [item.time, (item.ratio * 100).toFixed(2)];
        }),
      lineStyle: {
        type: "solid",
        color: "#2672ED",
      },
      itemStyle: {
        color: "#2672ED",
      },
      zlevel: 1,
      symbol: "none",
      markLine: {
        zlevel: 0,
        label: {
          show: false,
        },
        data: [{ yAxis: 2 }],
        silent: true,
        symbol: ["none", "none"],
        lineStyle: {
          color: "#CB525E",
          type: "dashed",
        },
      },
    },
  };

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <>
        {/* @ts-ignore */}
        <EchartsWrapper
          options={{ ...eventLogChartoptions }}
          loading={loading}
          className="active-peers-chart"
          style={{ height: "280px", width: "auto" }}
        />
      </>
    </Flex>
  );
};
