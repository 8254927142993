import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TabGroup from "src/components/Common/TabGroup";
import { API_CALL_STATE, WORKSPACE_INSIGHTS_FILTER } from "src/constants";
import {
  setEndDateForInsights,
  setFilterForInsights,
  setStartDateForInsights,
} from "src/store/insights/actions";
import { RootState } from "src/store/reducers";
import { Flex } from "@100mslive/roomkit-react";
import { convertDateToRange, convertRangeToDate } from "../helpers";

const tabs = [
  {
    name: "7d",
    title: "7d",
    id: 0,
  },
  {
    name: "15d",
    title: "15d",
    id: 1,
  },
  {
    name: "30d",
    title: "30d",
    id: 2,
  },
];
const weekTabs = [
  {
    name: "2w",
    title: "2w",
    id: 0,
  },
  {
    name: "3w",
    title: "3w",
    id: 1,
  },
  {
    name: "4w",
    title: "4w",
    id: 2,
  },
];

export default function WorkspaceFilter() {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    filter,
    fetchWorkspaceIssuesInsightsStatus,
    fetchWorkspacePeerInsightsStatus,
    fetchWorkspaceRecordingsInsightsStatus,
    fetchWorkspaceSessionInsightsStatus,
  } = useSelector((state: RootState) => state.insights);
  const [activeTab, setActiveTab] = useState("");

  const { tab = "", filter: queryFilter } = useParams<{
    tab: string;
    filter: string;
  }>();

  const isLoading =
    fetchWorkspaceIssuesInsightsStatus === API_CALL_STATE.IN_PROGRESS ||
    fetchWorkspacePeerInsightsStatus === API_CALL_STATE.IN_PROGRESS ||
    fetchWorkspaceRecordingsInsightsStatus === API_CALL_STATE.IN_PROGRESS ||
    fetchWorkspaceSessionInsightsStatus === API_CALL_STATE.IN_PROGRESS;

  useEffect(() => {
    if (
      fetchWorkspaceSessionInsightsStatus === "" ||
      !startDate ||
      !endDate ||
      !filter
    ) {
      const { startDate, endDate } = convertRangeToDate(tabs[0].name);
      dispatch(setStartDateForInsights(startDate as Date));
      dispatch(setEndDateForInsights(endDate as Date));
      dispatch(setFilterForInsights("daily"));
    }
  }, [
    dispatch,
    endDate,
    fetchWorkspaceSessionInsightsStatus,
    filter,
    startDate,
  ]);

  useEffect(() => {
    if (tab && !activeTab) {
      const { startDate, endDate } = convertRangeToDate(tab);
      if (startDate && endDate) {
        dispatch(setEndDateForInsights(endDate));
        dispatch(setStartDateForInsights(startDate));
      }
      setActiveTab(tab);
    }
  }, [tab, activeTab, dispatch]);
  useEffect(() => {
    if (queryFilter && !filter) {
      dispatch(setFilterForInsights(queryFilter));
    }
  }, [filter, queryFilter, dispatch]);

  useEffect(() => {
    if (!activeTab && startDate && endDate) {
      const range = convertDateToRange({
        startDate: startDate as Date,
        endDate: endDate as Date,
      });
      setActiveTab(range);
    }
  }, [activeTab, endDate, startDate]);
  const rangeTabs = useMemo(() => {
    return filter === WORKSPACE_INSIGHTS_FILTER.daily.toLowerCase()
      ? tabs
      : weekTabs;
  }, [filter]);
  return (
    <Flex css={{ gap: "$4" }}>
      {/* <PageFilter
        filter={filter}
        setFilter={filter => {
          dispatch(setFilterForInsights(filter));
          const str =
            filter === WORKSPACE_INSIGHTS_FILTER.daily.toLowerCase()
              ? tabs[0].name
              : weekTabs[0].name;
          const { startDate, endDate } = convertRangeToDate(str);
          setActiveTab(str);
          if (startDate && endDate) {
            dispatch(setStartDateForInsights(startDate));
            dispatch(setEndDateForInsights(endDate));
          }
        }}
      /> */}
      <TabGroup
        tabs={rangeTabs}
        disabled={isLoading}
        setActiveTab={(str: string) => {
          const { startDate, endDate } = convertRangeToDate(str);
          if (startDate && endDate) {
            dispatch(setEndDateForInsights(endDate));
            dispatch(setStartDateForInsights(startDate));
          }
          setActiveTab(str);
        }}
        activeTab={activeTab}
      />
    </Flex>
  );
}
