import React, { useState } from "react";
import moment from "moment";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { PeerFailureIssue, PeerIssueDetails } from "src/types/analytics";
import {
  ArrowRightIcon,
  ChevronDownIcon,
  ExitIcon,
  PoorConnectivityIcon,
} from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";

type Props = { data: PeerIssueDetails };

const peerIssueType = {
  subscribe_failures: {
    name: "Subscribe Failures",
    bg: "rgba(255, 128, 0, 0.1)",
    color: "rgba(255, 128, 0, 1)",
    key: "subscribe_failures",
    icon: (
      <SmallIcon>
        <ArrowRightIcon style={{ transform: "rotate(135deg)" }} />
      </SmallIcon>
    ),
  },
  publish_failures: {
    name: "Publish Failures",
    key: "publish_failures",
    bg: "rgba(237, 198, 2, 0.1)",
    color: "rgba(237, 198, 2, 1)",
    icon: (
      <SmallIcon>
        <ArrowRightIcon style={{ transform: "rotate(-45deg)" }} />
      </SmallIcon>
    ),
  },
  disconnections: {
    name: "Disconnections",
    bg: "rgba(20, 184, 129, 0.1)",
    color: "rgba(20, 184, 129, 1)",
    key: "disconnections",
    icon: (
      <SmallIcon>
        <PoorConnectivityIcon />
      </SmallIcon>
    ),
  },
  join_failures: {
    name: "Join Failures",
    bg: "rgba(204, 82, 95, 0.1)",
    color: "rgba(204, 82, 95, 1)",
    key: "join_failures",
    icon: (
      <SmallIcon>
        <ExitIcon />
      </SmallIcon>
    ),
  },
};

export default function PeerIssueLogs({ data }: Props) {
  const [issueOpenConfig, setIssueOpenConfig] = useState(
    {} as Record<keyof typeof peerIssueType, boolean>
  );
  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <Flex
        direction="column"
        css={{
          overflowY: "scroll",
          px: "$10",
          w: "100%",
          pt: "$md",
          height: "240px",
        }}
      >
        {Object.keys(peerIssueType).map(issue => {
          const noOfIssues = (data?.[issue as keyof typeof data] || []).length;
          const open = issueOpenConfig[issue as keyof typeof peerIssueType];
          const toggleOpen = () => {
            const duplicateIssueOpenConfig = { ...issueOpenConfig };
            duplicateIssueOpenConfig[issue as keyof typeof peerIssueType] =
              !open;
            setIssueOpenConfig(duplicateIssueOpenConfig);
          };
          return (
            <Flex
              direction="column"
              css={{
                pt: "$6",
                pb: open ? "0px" : "$6",
                borderTop: "$space$px solid $border_default",
              }}
            >
              <Flex justify={noOfIssues ? "between" : "start"} align="center">
                <Flex css={{ gap: "$6" }}>
                  <Flex
                    css={{ minHeight: "100%", c: "$on_surface_low" }}
                    align="center"
                  >
                    {peerIssueType[issue as keyof typeof peerIssueType].icon}
                  </Flex>
                  <Flex direction="column">
                    <Text variant="caption" css={{ fontWeight: "$semiBold" }}>
                      {peerIssueType[issue as keyof typeof peerIssueType].name}
                    </Text>
                    <Text
                      variant="caption"
                      css={{ fontWeight: "$regular", c: "$on_surface_medium" }}
                    >{`${noOfIssues} issues`}</Text>
                  </Flex>
                </Flex>
                {noOfIssues ? (
                  <Box
                    css={{
                      cursor: "pointer",
                      transform: open ? "rotate(180deg)" : "",
                    }}
                    onClick={toggleOpen}
                  >
                    <SmallIcon>
                      <ChevronDownIcon />
                    </SmallIcon>
                  </Box>
                ) : (
                  <></>
                )}
              </Flex>
              {open ? (
                <Flex direction="column">
                  {data[issue as keyof typeof data]?.map(
                    (datum: PeerFailureIssue) => {
                      return (
                        <IssueMessage datum={datum} key={datum.timestamp} />
                      );
                    }
                  )}
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}

const IssueMessage = ({ datum }: { datum: PeerFailureIssue }) => {
  const date = moment(datum.timestamp).format("HH:mm:ss");

  return (
    <Flex
      direction="row"
      align="center"
      justify="between"
      css={{
        gap: "$space$8",
        borderBottom: "$space$px solid $border_default",
        py: "$3",
        "&:last-child": { border: "none !important" },
      }}
    >
      <Text variant="caption" css={{ fontWeight: "$regular" }}>
        {datum.error_message}
      </Text>
      <Text
        variant="caption"
        css={{ fontWeight: "$regular", c: "$on_surface_medium" }}
      >
        {date}
      </Text>
    </Flex>
  );
};
