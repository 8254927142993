import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { fetchPeersAnalyticsQualityIssueDetails } from "src/store/analytics/actions";
import { RootState } from "src/store/reducers";
import {
  peerMosScoreDataType,
  peerQualityIssueType,
} from "src/types/analytics";
import { AlertTriangleIcon, SupportIcon } from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import MOSInformation from "../components/MOSInformation";

const infoFlexCss = {
  borderTopRightRadius: "$1",

  flex: "1 1 0",
  flexWrap: "wrap",
  w: "100%",
  borderBottom: "$space$1 solid $border_default",
  borderTop: "$space$1 solid $border_default",
  mt: "$10",
  pt: "$10",
  px: "$10",
  borderRight: "$space$1 solid $border_default",
};
type Props = { peerId: string };

export default function PeerQualityIssues({ peerId }: Props) {
  const dispatch = useDispatch();

  const {
    peerQualityIssues,
    peerMosScore,
    fetchPeerAnalyticsQualityIssueDetailsStatus,
  } = useSelector((state: RootState) => state.roomDebugger);

  useEffect(() => {
    if (peerId) {
      dispatch(fetchPeersAnalyticsQualityIssueDetails(peerId));
    }
  }, [peerId, dispatch]);

  return (
    <Flex css={{ ...infoFlexCss }}>
      {fetchPeerAnalyticsQualityIssueDetailsStatus !== API_CALL_STATE.FAILED ? (
        <MOSInformation
          issuesData={peerQualityIssues as peerQualityIssueType}
          mosScoreData={peerMosScore as peerMosScoreDataType}
          issuesLoading={
            fetchPeerAnalyticsQualityIssueDetailsStatus ===
            API_CALL_STATE.IN_PROGRESS
          }
        />
      ) : (
        <Flex css={{ w: "100%", textAlign: "center" }}>
          <Flex direction="column" css={{ w: "100%" }}>
            <Flex justify="between" align="center" css={{ w: "100%" }}>
              <Text variant="sub2" css={{ fontWeight: 600 }}>
                Quality Issues
              </Text>
            </Flex>
            <Flex
              css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              justify="center"
              align="center"
              direction="column"
            >
              <Flex css={{ c: "$alert_error_default" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column">
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    c: "$on_surface_medium",
                    w: "20rem",
                  }}
                >
                  We couldn&apos;t load details for this chart. This feature is
                  currently in alpha and there are known issues we&apos;re
                  working on.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
