import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import RoomStateLabel from "src/components/Cards/RoomStateLabel";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import InspectButton from "src/components/Common/InspectButton";
import OptionItem from "src/components/Common/OptionItem";
import Options from "src/components/Common/Options";
import { API_CALL_STATE } from "src/constants";
import { getVisibleRoles, history } from "src/helpers";
import LargeRoomDialog from "src/modules/rooms/components/Forms/Dialog/LargeRoomDialog";
import JoinRoomModal from "src/pages/Dashboard/JoinRoomModal";
import RoomDetailsCard from "src/pages/Rooms/RoomDetailsCard";
import { roomRTMPStreamKeyType } from "src/types/roomCodeTypes";
import { roomDetailsType } from "src/types/roomTypes";
import { isAdmin } from "src/utils";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  InfoIcon,
  PeopleIcon,
  RejectIcon,
  SettingsIcon,
  VerticalMenuIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Text, Tooltip } from "@100mslive/roomkit-react";

type Props = {
  hlsEnabled: boolean;
  roomId: string;
  templateExists: boolean;
  room: roomDetailsType;
  toggleRoomStatus: () => void;
  toggleLargeRoomBool: (val: boolean) => void;
  fetchRoomDetailsStatus: string;
  fetchRTMPStreamKeysStatus: string;
  disableRTMPStreamKeysStatus: string;
  createRTMPStreamKeysStatus: string;
  roomStreamKeys: Record<string, roomRTMPStreamKeyType>;
  disableRTMPKeys: (roomId: string) => void;
  createAndFetchRTMPStreamKeys: (roomId: string) => void;
  refreshRoomRTMPStreamKeys: (roomId: string) => void;
};

// eslint-disable-next-line complexity
function RoomDetailsInfo({
  templateExists,
  toggleRoomStatus,
  roomId,
  hlsEnabled,
  room,
  disableRTMPStreamKeysStatus,
  fetchRoomDetailsStatus,
  fetchRTMPStreamKeysStatus,
  createRTMPStreamKeysStatus,
  roomStreamKeys,
  disableRTMPKeys,
  createAndFetchRTMPStreamKeys,
  refreshRoomRTMPStreamKeys,
  toggleLargeRoomBool,
}: Props) {
  const [openRoomLinks, setOpenRoomLinks] = useState(false);
  const [openWithActiveRole, setOpenWithActiveRole] = useState("");
  const [openLargeRoomModal, setOpenLargeRoomModal] = useState(false);

  return (
    <>
      <Flex css={{ w: "100%" }} justify="between" align="center">
        <Flex justify="start" align="center" css={{ gap: "$md" }}>
          <Box
            css={{ cursor: "pointer", c: "$on_surface_medium" }}
            onClick={() => history.goBack()}
          >
            <LargeIcon>
              <ChevronLeftIcon />
            </LargeIcon>
          </Box>
          <Text variant="h5">Room Details</Text>{" "}
          {templateExists === false ||
          (templateExists === true && !room?.enabled) ? (
            <Tooltip
              side="bottom"
              disabled={false}
              align="center"
              title={
                templateExists === false ? (
                  <Text
                    variant="xs"
                    css={{
                      color: "$on_surface_high",
                      fontWeight: "$semiBold",
                    }}
                  >
                    Template for the room has been deleted
                  </Text>
                ) : (
                  <Flex
                    align="center"
                    css={{ color: "$on_surface_high", gap: "$3" }}
                  >
                    <InfoIcon height={16} width={16} />
                    <Text
                      variant="xs"
                      css={{
                        color: "$on_surface_high",
                        fontWeight: "$semiBold",
                      }}
                    >
                      This room has been disabled.
                    </Text>
                    <Text
                      variant="xs"
                      onClick={() => {
                        toggleRoomStatus();
                      }}
                      css={{
                        color: "$primary_bright",
                        fontWeight: "$semiBold",
                        "&:hover": { color: "$primary_default" },
                        cursor: "pointer",
                      }}
                    >
                      Enable.
                    </Text>
                  </Flex>
                )
              }
            >
              <Flex css={{ ml: "$4" }}>
                <RoomStateLabel labelText="Disabled" />
              </Flex>
            </Tooltip>
          ) : (
            <></>
          )}
          {room?.large_room ? (
            <Flex css={{ ml: "$4" }}>
              <RoomStateLabel labelText="Large Room" />
            </Flex>
          ) : (
            <></>
          )}
        </Flex>

        {fetchRoomDetailsStatus === API_CALL_STATE.IN_PROGRESS ? (
          <>
            <Skeleton width="100%" height="500px" />
          </>
        ) : fetchRoomDetailsStatus === API_CALL_STATE.DONE ? (
          <Flex gap="3">
            <InspectButton
              componentId="rooms.details.page"
              btnId="inspect.room"
              link={`/events-inspector?id=${roomId}`}
            />

            {openRoomLinks ? (
              <JoinRoomModal
                open={openRoomLinks}
                openWithActiveRole={openWithActiveRole}
                setOpen={val => setOpenRoomLinks(val)}
                roomId={roomId}
                subdomain={room?.domain || ""}
                templateId={room?.template_id}
                componentId="rooms.details.page"
              />
            ) : (
              <></>
            )}
            <Tooltip
              disabled={room.enabled && getVisibleRoles(room.roles).length > 0}
              title={
                !room.enabled
                  ? "Room is disabled"
                  : getVisibleRoles(room.roles).length < 1
                  ? "No roles are available"
                  : ""
              }
            >
              <Button
                variant="primary"
                onClick={() => {
                  setOpenRoomLinks(true);
                }}
                disabled={
                  isAdmin() ||
                  !room?.enabled ||
                  getVisibleRoles(room?.roles).length < 1
                }
                css={{ fontSize: "$sm", h: "$12", r: "$0" }}
              >
                Join Room
              </Button>
            </Tooltip>

            {templateExists !== false ? (
              <Options
                Icon={
                  <Button
                    outlined
                    variant="standard"
                    css={{ fontSize: "$sm", h: "$12", r: "$0", px: "$3" }}
                  >
                    <VerticalMenuIcon width={20} height={20} />
                  </Button>
                }
                content={[
                  {
                    data: (
                      <OptionItem
                        icon={
                          room?.enabled ? <RejectIcon /> : <CheckCircleIcon />
                        }
                        title={room?.enabled ? "Disable Room" : "Enable Room"}
                      />
                    ),
                    func: async () => {
                      toggleRoomStatus();
                    },
                  },

                  {
                    data: (
                      <OptionItem
                        link={`/templates/${room?.template_id}`}
                        icon={<SettingsIcon />}
                        title="View Template"
                      />
                    ),
                    func: () => {
                      return;
                    },
                  },
                  {
                    data: (
                      <OptionItem
                        icon={<PeopleIcon />}
                        title={
                          !room.large_room
                            ? "Switch to Large Room"
                            : "Switch to Normal Room"
                        }
                      />
                    ),
                    func: () => {
                      setOpenLargeRoomModal(true);
                    },
                  },
                ]}
              />
            ) : null}
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
      <RoomDetailsCard
        setOpenWithActiveRole={val => {
          setOpenRoomLinks(true);
          setOpenWithActiveRole(val);
        }}
        fetchRTMPStreamKeysStatus={fetchRTMPStreamKeysStatus}
        createRoomRTMPKeysStatus={createRTMPStreamKeysStatus}
        refreshRTMPStreamKey={refreshRoomRTMPStreamKeys}
        disableRTMPStreamKey={disableRTMPKeys}
        templateExists={templateExists}
        isRoomActive={room?.enabled || false}
        template_id={room?.template_id}
        room={room}
        role_names={room?.roles || []}
        room_id={roomId}
        isHLSEnabled={hlsEnabled}
        createRoomRTMPKeys={createAndFetchRTMPStreamKeys}
        roomRTMPDetails={roomStreamKeys}
        disableRTMPStreamKeysStatus={disableRTMPStreamKeysStatus}
      />
      <LargeRoomDialog
        setTrigger={() => setOpenLargeRoomModal(false)}
        trigger={openLargeRoomModal}
        largeRoom={room.large_room || false}
        setLargeRoom={val => toggleLargeRoomBool(val)}
      />
    </>
  );
}

export default RoomDetailsInfo;
