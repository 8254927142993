import React, { memo, useMemo, useState } from "react";
import { isFunction } from "lodash";
import { TileWrapper } from "src/components/Common/Tile";
import { Box, CSS, Flex, Text } from "@100mslive/roomkit-react";
import { steps } from "../../containers/OnboardingFlowWithLayout";
import { addonConfig } from "../../types/form";

type Props = {
  configs: addonConfig[];
  stepAnswers: Record<string, any>;
  setStepAnswers: (answers: Record<string, any>) => void;
  step: string;
  css?: CSS;
};

export const SetTemplateStepsConfigurationForm = memo(
  ({ configs, stepAnswers, setStepAnswers, step, css }: Props) => {
    const configArray = useMemo(() => {
      return configs.map(c => c.key);
    }, [configs]);
    const [keyOfQuestionsToShow, setKeyOfQuestionsToShow] = useState<string[]>(
      configArray || []
    );

    return (
      <Box
        css={{
          w: "100%",
          maxHeight: "344px",
          h: "calc(100vh - 430px)",
          overflowY: "auto",
          ...css,
        }}
        id="form-content-wrapper"
      >
        <Box css={{ p: "$10" }} id="form-content-form">
          {configs.length > 0
            ? configs.map((config, i) => (
                <QuestionAnswerFlex
                  config={config}
                  currIndex={i}
                  isHidden={!keyOfQuestionsToShow.includes(config.key)}
                  setAnswer={(key, val) => {
                    setStepAnswers({
                      ...stepAnswers,
                      [step]: {
                        ...stepAnswers[step],
                        [key]: val,
                      },
                    });
                    if (isFunction(config?.filterKeyOfVisibleQuestion)) {
                      setKeyOfQuestionsToShow(
                        config.filterKeyOfVisibleQuestion(val)
                      );
                    }
                  }}
                />
              ))
            : null}
        </Box>
      </Box>
    );
  }
);

//refactor this to use the above component and remove this component altogether
//inherits the answer flex but has a slightly different handling then rest of the forms
export function LiveStreamingAddOnForm({
  configs,
  stepAnswers,
  setStepAnswers,
}: Props) {
  return (
    <Box
      css={{
        w: "100%",
        maxHeight: "344px",
        h: "calc(100vh - 430px)",
        overflowY: "auto",
      }}
      id="form-content-wrapper"
    >
      <Box css={{ p: "$10" }} id="form-content-form">
        {configs.length > 0
          ? configs.map((config, i) => (
              <QuestionAnswerFlex
                config={config}
                currIndex={i}
                isHidden={
                  i === 0
                    ? false
                    : !stepAnswers?.[steps.SELECT_ADD_ON.key]?.[
                        "recording"
                      ]?.includes(configs[0]?.defaultValue)
                }
                setAnswer={(key, val) => {
                  setStepAnswers({
                    ...stepAnswers,
                    [steps.SELECT_ADD_ON.key]: {
                      ...stepAnswers[steps.SELECT_ADD_ON.key],
                      [key]: val,
                    },
                  });
                }}
              />
            ))
          : null}
      </Box>
    </Box>
  );
}

//generic form for all others features
function UseCaseQuestionForm({
  configs,
  stepAnswers,
  setStepAnswers,
  step,
}: Props) {
  return (
    <Box
      css={{
        w: "100%",
        maxHeight: "324px",
        h: "calc(100vh - 430px)",
        overflowY: "auto",
      }}
      id="form-content-wrapper"
    >
      <Box css={{ p: "$10" }} id="form-content-form">
        {configs.length > 0
          ? configs.map((config, i) => (
              <QuestionAnswerFlex
                config={config}
                currIndex={i}
                setAnswer={(key, val) => {
                  setStepAnswers({
                    ...stepAnswers,
                    [step]: {
                      ...stepAnswers[step],
                      [key]: val,
                    },
                  });
                }}
              />
            ))
          : null}
      </Box>
    </Box>
  );
}

export default UseCaseQuestionForm;

//question answer flex
export const QuestionAnswerFlex = ({
  config,
  currIndex,
  setAnswer,
  isHidden = false,
}: {
  config: addonConfig;
  isHidden?: boolean;
  setAnswer: (key: string, val: string) => void;
  currIndex: number;
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(0);
  return !isHidden ? (
    <>
      <Text
        css={{
          color: "$on_surface_high",
          fontWeight: "$semiBold",
          mt: currIndex === 0 ? "" : "$10",
        }}
        variant="body1"
      >
        {config?.question}
      </Text>
      <Flex css={{ flexWrap: "wrap", mt: "$md" }}>
        {config.answers.map((answer, index) => {
          return (
            <TileWrapper
              css={{
                border: `$space$px solid ${
                  selectedAnswerIndex === index
                    ? "$primary_default"
                    : "$border_bright"
                }`,
                mr: "$md",
                p: "$6 $9",
                bg: selectedAnswerIndex === index ? "$primary_dim" : "",
                alignItems: "center",
              }}
              onClick={() => {
                setSelectedAnswerIndex(index);
                setAnswer(config.key, answer.title);
              }}
              isActive={selectedAnswerIndex === index}
            >
              {answer?.icon ? answer.icon : <></>}
              <Text css={{ ml: answer?.icon ? "$4" : "0" }} variant="body2">
                {answer.title}
              </Text>
            </TileWrapper>
          );
        })}
      </Flex>
      {config?.answers?.[selectedAnswerIndex]?.infoText ? (
        <Text
          variant="sub2"
          css={{
            fontWeight: "$regular",
            mt: "$md",
            c: "$on_surface_low",
          }}
        >
          {config?.answers[selectedAnswerIndex]?.infoText}

          {config.answers[selectedAnswerIndex]?.infoLink ? (
            <a href={config.answers[selectedAnswerIndex]?.infoLink}>
              <Text
                as="span"
                css={{
                  c: "$primary_default",
                  "&:hover": { c: "$primary_bright" },
                }}
              >
                Learn more.
              </Text>
            </a>
          ) : null}
        </Text>
      ) : null}
    </>
  ) : null;
};
