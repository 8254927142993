import React from "react";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { MathStats } from "src/helpers";
import { peerAnalyticsSubscribeType } from "src/types/analytics";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/roomkit-react";

type Props = {
  subscribeData: peerAnalyticsSubscribeType;
};

const getPacketLossSentiment = (packetLoss: number) => {
  if (packetLoss < 1) {
    return { text: "Good", color: "$alert_success" };
  }
  if (packetLoss < 5) {
    return { text: "Fair", color: "$alert_warning" };
  }
  return { text: "Bad", color: "$alert_error_default" };
};

function TrackMessage({
  text,
  val,
  tooltip = "",
}: {
  text: string;
  val: string;
  tooltip?: string;
}) {
  const sentiment = getPacketLossSentiment(Number(val));
  const isDefaultTrack = text === "Threshold Packet Loss";
  return (
    <Flex
      css={{
        p: "$8 $10",
      }}
      justify="start"
      direction="column"
    >
      <Flex align="center" css={{ gap: "$2" }}>
        <Text variant="caption" css={{ c: "$on_surface_low" }}>
          {text}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ r: "$0", w: "$80" }}
          >
            <Flex css={{ c: "$on_surface_low" }}>
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Text
        variant="body2"
        css={{
          c: isDefaultTrack ? "$on_surface_medium" : sentiment.color,
          mt: "$2",
          fontWeight: "$regular",
        }}
      >
        {isDefaultTrack ? `${val}%` : `${val}% - ${sentiment.text}`}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function SubscriberPacketLossMetrics({ subscribeData }: Props) {
  const mathStats = new MathStats();
  const calulateP95PacketLoss = () => {
    if (isEmpty(subscribeData?.packet_loss)) {
      return 0;
    }
    let totalDuration = 0;
    const data = subscribeData.packet_loss;
    const packetLossesArray = [];
    if (data.length === 0) {
      return 0;
    }
    for (let i = 0; i < data.length - 1; i++) {
      const duration =
        (new Date(data[i + 1].value?.[0]).getTime() -
          new Date(data[i].value?.[0]).getTime()) /
        1000;
      totalDuration += duration;
      if (duration > 0 && Number.isFinite(data[i].value?.[1])) {
        packetLossesArray.push(data[i].value?.[1]);
      }
    }

    // Add the last data point normalized by the average duration
    if (data.length > 1 && totalDuration > 0) {
      packetLossesArray.push(data[data.length - 1].value?.[1]);
    } else if (data.length === 1) {
      packetLossesArray.push(data?.[0].value?.[1]);
    }

    return mathStats.quantile(packetLossesArray, 0.95);
  };
  const p95PacketLoss = calulateP95PacketLoss();

  return (
    <Flex
      css={{
        borderTop: "$space$px solid $border_default",
        borderLeft: "$space$px solid $border_default",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      <TrackMessage
        text="P95 Packet Loss"
        val={p95PacketLoss.toFixed(2).toString()}
      />
      <TrackMessage text="Threshold Packet Loss" val="5" />
    </Flex>
  );
}
