import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { RootState } from "src/store/reducers";
import { fetchSessionTimelineDetails } from "src/store/sessions/actions";
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  NoEntryIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import { SessionEventLogs } from "../components/SessionEventLogs";
import "react-loading-skeleton/dist/skeleton.css";
type Props = {
  sessionId: string;
};

const infoFlexCss = {
  r: "$1",

  flex: "1 1 0",
  flexWrap: "wrap",
  w: "100%",
  border: "$space$1 solid $border_default",
};
// eslint-disable-next-line complexity
const SessionTimeline = ({ sessionId }: Props) => {
  const dispatch = useDispatch();
  const fetchSessionTimelineDetailsStatus = useSelector(
    (state: RootState) => state.sessions.fetchSessionTimelineDetailsStatus
  );
  const sessionTimelineDetails = useSelector(
    (state: RootState) => state.sessions.sessionTimelineDetails
  );
  const isSessionLive = useSelector(
    (state: RootState) => state.sessions.derivedStates.isSessionLive
  );
  const [showSessionTimeline, setShowSessionTimeline] = useState(true);

  useEffect(() => {
    if (sessionId && !isSessionLive && typeof isSessionLive === "boolean") {
      dispatch(fetchSessionTimelineDetails(sessionId));
    }
  }, [dispatch, isSessionLive, sessionId]);
  if (isSessionLive || typeof isSessionLive !== "boolean") {
    return <></>;
  }
  return (
    <Flex
      direction="column"
      css={{
        w: "100%",
        border: "$space$px solid $border_default",
        r: "$1",
        px: "$10",
        py: "$9",
      }}
    >
      <Flex
        justify="between"
        align="center"
        onClick={() => setShowSessionTimeline(!showSessionTimeline)}
        css={{
          cursor: "pointer",
        }}
      >
        {fetchSessionTimelineDetailsStatus === API_CALL_STATE.IN_PROGRESS ? (
          <Skeleton width={160} height="100%" />
        ) : (
          <Text
            variant="sub2"
            css={{
              fontWeight: "$semiBold",
            }}
          >
            Session Timeline
          </Text>
        )}
        <Flex>
          <LargeIcon>
            <ChevronDownIcon
              style={{
                transform: showSessionTimeline ? "rotate(180deg)" : "none",
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </LargeIcon>
        </Flex>
      </Flex>
      <Flex
        css={{
          overflow: "hidden",
          visibility: showSessionTimeline ? "visible" : "hidden",
          transition:
            "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
          maxHeight: showSessionTimeline ? "700px" : "0",
        }}
        direction="column"
      >
        {fetchSessionTimelineDetailsStatus === API_CALL_STATE.FAILED ? (
          <Flex css={{ ...infoFlexCss, py: "$8", px: "$10" }}>
            <Flex
              css={{
                rowGap: "$md",
                minHeight: "240px",
                w: "100%",
                textAlign: "center",
              }}
              justify="center"
              align="center"
              direction="column"
            >
              <Flex css={{ c: "$alert_error_default" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column">
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    c: "$on_surface_medium",
                    w: "20rem",
                  }}
                >
                  We couldn&apos;t load details for this session.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex direction="column">
            {fetchSessionTimelineDetailsStatus === API_CALL_STATE.DONE ? (
              !isEmpty(sessionTimelineDetails) ? (
                <SessionEventLogs eventLogs={sessionTimelineDetails} />
              ) : (
                <Flex
                  direction="column"
                  css={{
                    rowGap: "$md",
                    minHeight: "240px",
                    w: "100%",
                    textAlign: "center",
                  }}
                  justify="center"
                  align="center"
                >
                  <Flex css={{ c: "$on_surface_low" }}>
                    <NoEntryIcon width={40} height={40} />
                  </Flex>
                  <Flex direction="column">
                    <Text variant="sub2" css={{ mb: "$2" }}>
                      No event logs
                    </Text>
                    <Text
                      variant="caption"
                      css={{
                        fontWeight: "$regular",
                        c: "$on_surface_medium",
                        w: "20rem",
                      }}
                    >
                      Logs for session events will appear here, detailing
                      recording, streaming, and other activities as they occur.
                    </Text>
                  </Flex>
                </Flex>
              )
            ) : (
              <Skeleton width="100%" height={240} />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default SessionTimeline;
