import React from "react";
import { Flex } from "@100mslive/roomkit-react";
import { EventLogChart } from "./charts/eventLogChart";

export const EventLogs = ({
  eventLogs,
}: {
  eventLogs:
    | {
        video: { message: string; timestamp: string; type: string }[];
        audio: { message: string; timestamp: string; type: string }[];
        errors: { message: string; timestamp: string; type: string }[];
        device: { message: string; timestamp: string; type: string }[];
        screen: { message: string; timestamp: string; type: string }[];
      }
    | {};
}) => {
  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <EventLogChart eventLogs={eventLogs} />
    </Flex>
  );
};
