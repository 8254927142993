import React from "react";
import { useDispatch } from "react-redux";
import VSmallIcon from "src/components/Common/Icons/VSmallIcon";
import { applySessionFilter } from "src/store/sessions/actions";
import { SessionIssueData } from "src/types/sessions";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/roomkit-react";
const firstRowIssuesKeys = {
  quality_issues: {
    key: "quality_issues",
    name: "Quality Issues",
    tooltip:
      "Represent overall connection quality affecting audio/video quality during a session. An issue is marked a quality issue when the number of samples with a quality score below 3.5 accounts for 10% or more of the total samples, indicating potential problems with the audio/video experience.",
  },
  join_failure: {
    key: "join_failure",
    name: "Join Failures",
    tooltip:
      "Issues encountered by peers when they fail to join a session successfully.",
  },
  publish_failure: {
    key: "publish_failure",
    name: "Publish Failures",
    tooltip:
      "Issues faced by peers when unable to publish their audio or video streams to other participants during a session.",
  },
};
const secondRowIssuesKeys = {
  subscribe_failure: {
    key: "subscribe_failure",
    name: "Subscribe Failures",
    tooltip:
      "Issues related to peers being unable to subscribe to or receive audio/video streams from remote peers, resulting in poor or no incoming media quality.",
  },
  disconnections: {
    key: "disconnections",
    name: "Disconnections",
    tooltip:
      "Issues encountered by peers when they get disconnected during a session.",
  },
};

export const SessionIssues = ({
  sessionIssuesData,
  totalNoOfPeers,
  sessionMongoPeers,
}: {
  sessionIssuesData: SessionIssueData;
  totalNoOfPeers: number;
  sessionMongoPeers: string[];
}) => {
  const dispatch = useDispatch();
  const peersAffected = sessionIssuesData?.peers_affected || [];
  const mongoPeersAffected = peersAffected.filter(peer => {
    // Check if peer is present in mongo peers
    return sessionMongoPeers.includes(peer);
  });

  const applyFilter = (key: string) => {
    dispatch(applySessionFilter("issue", key));
    dispatch(applySessionFilter("applyFilter", true));
  };

  return (
    <Flex css={{ w: "100%", h: "100%" }}>
      <Flex
        css={{
          h: "100%",
          aspectRatio: 1,
          borderTop: "$space$px solid $border_default",
          borderRight: "$space$px solid $border_default",
          p: "$10",
        }}
        direction="column"
        justify="center"
        align="center"
      >
        <Text
          variant="overline"
          css={{
            textTransform: "uppercase",
            c: "$on_surface_medium",
            mb: "$4",
          }}
        >
          PEERS with issues
        </Text>

        <Text variant="h5" css={{ mb: "$4" }}>
          {`${mongoPeersAffected?.length || 0} of ${totalNoOfPeers}`}
        </Text>
        <Text
          variant="caption"
          css={{ c: "$on_surface_low", fontWeight: "$semiBold" }}
        >
          {`${(
            ((mongoPeersAffected?.length || 0) / totalNoOfPeers) *
            100
          ).toFixed(2)} % `}
          <Text variant="caption" as="span" css={{ c: "$on_surface_low" }}>
            peers affected
          </Text>
        </Text>
      </Flex>
      <Flex direction="column" css={{ w: "100%", h: "100%" }}>
        <Flex css={{ w: "100%", h: "50%" }}>
          {Object.keys(firstRowIssuesKeys).map(key => {
            let val = 0;
            const actualAffectedMongoPeers = (
              sessionIssuesData?.[key as keyof typeof sessionIssuesData] || []
            ).filter(peer => {
              return sessionMongoPeers.includes(peer);
            });
            val = actualAffectedMongoPeers.length as number;

            const percent = (((val || 0) / totalNoOfPeers) * 100).toFixed(2);
            return (
              <Flex
                direction="column"
                css={{
                  w: "33%",
                  p: "$md $10",
                  borderTop: "$space$px solid $border_default",
                  borderRight: "$space$px solid $border_default",
                  "&:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                <Flex align="center">
                  <Text
                    variant="overline"
                    css={{ c: "$on_surface_medium", mr: "$2" }}
                  >
                    {firstRowIssuesKeys[
                      key as keyof typeof firstRowIssuesKeys
                    ].name.toUpperCase()}
                  </Text>

                  <Tooltip
                    side="top"
                    align="center"
                    boxCss={{ maxWidth: "400px", r: "$1" }}
                    title={
                      firstRowIssuesKeys[key as keyof typeof firstRowIssuesKeys]
                        .tooltip
                    }
                  >
                    <Flex
                      align="center"
                      css={{
                        c: "$on_surface_low",
                        cursor: "pointer",
                        h: "100%",
                      }}
                    >
                      <VSmallIcon>
                        <InfoIcon />
                      </VSmallIcon>
                    </Flex>
                  </Tooltip>
                </Flex>
                <Flex>
                  <Flex direction="column" className="w-full">
                    <Text variant="sub1">
                      {`${val ? `${val}` : "No"} peers`}
                    </Text>
                    <Flex
                      justify="between"
                      className="lg:items-center flex-col lg:flex-row gap-1"
                    >
                      <Text variant="caption" css={{ c: "$on_surface_low" }}>
                        {`${parseFloat(percent) ? percent : "0"}% affected`}
                      </Text>
                      {parseFloat(percent) ? (
                        <Text
                          variant="md"
                          css={{
                            color: "$primary_bright",
                            "&:hover": {
                              cursor: "pointer",
                              textDecoration: "underline",
                            },
                          }}
                          onClick={() => {
                            applyFilter(
                              firstRowIssuesKeys[
                                key as keyof typeof firstRowIssuesKeys
                              ].key
                            );
                          }}
                        >
                          View &rarr;
                        </Text>
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
        <Flex css={{ w: "100%", h: "50%" }}>
          {Object.keys(secondRowIssuesKeys).map(key => {
            let val = 0;
            const actualAffectedMongoPeers = (
              sessionIssuesData?.[key as keyof typeof sessionIssuesData] || []
            ).filter(peer => {
              return sessionMongoPeers.includes(peer);
            });
            val = actualAffectedMongoPeers.length as number;

            const percent = (((val || 0) / totalNoOfPeers) * 100).toFixed(2);
            return (
              <Flex
                direction="column"
                css={{
                  w: "50%",
                  p: "$md $10",
                  borderTop: "$space$px solid $border_default",
                  borderRight: "$space$px solid $border_default",
                  "&:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                <Flex align="center">
                  <Text
                    variant="overline"
                    css={{ mr: "$2", c: "$on_surface_medium" }}
                  >
                    {secondRowIssuesKeys[
                      key as keyof typeof secondRowIssuesKeys
                    ].name.toUpperCase()}
                  </Text>

                  <Tooltip
                    side="top"
                    align="center"
                    boxCss={{ maxWidth: "400px", r: "$1" }}
                    title={
                      secondRowIssuesKeys[
                        key as keyof typeof secondRowIssuesKeys
                      ].tooltip
                    }
                  >
                    <Flex
                      align="center"
                      css={{
                        c: "$on_surface_low",
                        cursor: "pointer",
                        h: "100%",
                      }}
                    >
                      <VSmallIcon>
                        <InfoIcon />
                      </VSmallIcon>
                    </Flex>
                  </Tooltip>
                </Flex>
                <Flex>
                  <Flex direction="column" className="w-full">
                    <Text variant="sub1">{`${
                      val ? `${val}` : "No"
                    } peers`}</Text>
                    <Flex
                      justify="between"
                      className="lg:items-center flex-col lg:flex-row gap-1"
                    >
                      <Text
                        variant="caption"
                        css={{ c: "$on_surface_low" }}
                      >{`${parseFloat(percent) ? percent : 0}% affected`}</Text>

                      {parseFloat(percent) ? (
                        <Text
                          variant="md"
                          css={{
                            color: "$primary_bright",
                            "&:hover": {
                              cursor: "pointer",
                              textDecoration: "underline",
                            },
                          }}
                          onClick={() => {
                            applyFilter(
                              secondRowIssuesKeys[
                                key as keyof typeof secondRowIssuesKeys
                              ].key
                            );
                          }}
                        >
                          View &rarr;
                        </Text>
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
