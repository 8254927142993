/* eslint-disable complexity */
import React from "react";
import VSmallIcon from "src/components/Common/Icons/VSmallIcon";
import { peerIceCandidates } from "src/types/analytics";
import { ArrowRightIcon, InfoIcon } from "@100mslive/react-icons";
import { Box, Flex, Text, Tooltip } from "@100mslive/roomkit-react";

type Props = { peerWebrtcData: peerIceCandidates };

export const peerWebrtcInfo = {
  Publisher: "publisher",
  Subscriber: "subscriber",
};
export const peerWebtrcInfoTooltips = {
  Publisher:
    "Specifies the type of network candidate used for publishing, like Host, Server Reflexive (srflx), Peer Reflexive (prflx), or Relay.",
  Subscriber:
    "Specifies the type of network candidate used for subscribing, like Host, Server Reflexive (srflx), Peer Reflexive (prflx), or Relay.",
};
const PeerICECandidatesWebrtcDetails = ({ peerWebrtcData }: Props) => {
  const listOfInfo = Object.keys(peerWebrtcInfo);

  return listOfInfo.map((key: string) => {
    const val =
      peerWebrtcData?.[
        peerWebrtcInfo[
          key as keyof typeof peerWebrtcInfo
        ] as keyof typeof peerWebrtcData
      ] || [];
    return (
      <Flex key={key} css={{ gap: "$8", w: "50%" }}>
        <Flex
          css={{
            c: "$on_surface_low",
            mr: key === "Publisher" ? "$0" : "2px",
            mb: key === "Publisher" ? "$0" : "2px",
            transform: key === "Publisher" ? "rotate(-45deg)" : "rotate(45deg)",
          }}
        >
          <ArrowRightIcon />
        </Flex>

        <Flex direction="column">
          {" "}
          <Flex gap={1} align="center" css={{ mb: "$4" }}>
            <Text
              variant="tiny"
              css={{
                color: "$on_surface_medium",
                fontWeight: "$semiBold",
                textTransform: "uppercase",
                letterSpacing: "1.5px",
              }}
            >
              {key}
            </Text>
            <Tooltip
              boxCss={{ w: "$60", r: "$0" }}
              side="top"
              align="center"
              title={
                peerWebtrcInfoTooltips[
                  key as keyof typeof peerWebtrcInfoTooltips
                ]
              }
            >
              <Box css={{ color: "$on_surface_medium" }}>
                <VSmallIcon>
                  <InfoIcon />
                </VSmallIcon>
              </Box>
            </Tooltip>
          </Flex>
          <Flex justify="start" align="center">
            <Text variant="body2" css={{}}>
              {val.length > 0 ? val.join(", ") : "-"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  });
};

export default PeerICECandidatesWebrtcDetails;
