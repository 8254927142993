import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import CopyButton from "src/components/Common/CopyButton";
import NoResults from "src/components/Common/NoResults";
import Table from "src/components/Common/TableNew";
import TablePagination from "src/components/TablePagination/TablePagination";
import TruncatedColumn from "src/components/TruncatedColumn/TruncatedColumn";
import { INTERNAL_RECORDER_ROLE } from "src/helpers";
import { RootState } from "src/store/reducers";
import { sortFilteredPeers } from "src/store/sessions/actions";
import { Peer } from "src/types/sessions";
import { formattedDate, getLocalZone, secondsToHMS } from "src/utils";
import { Text } from "@100mslive/roomkit-react";
import { SessionIssueColumn } from "./SessionIssueColumn";
import { sortSessionPeerTableColumn } from "../helpers/sortSessionPeerTableColumn";

const zoneAbbr = getLocalZone();

const headers = [
  "Peer Name",
  "Role",
  "Peer ID",
  "User ID",
  "Issues",
  `Start Time (${zoneAbbr})`,
  `End Time (${zoneAbbr})`,
  "Peer Duration",
];

interface Props {
  isSessionLive: boolean;
  isSearch: boolean;
  loading: boolean;
  peers: { [key: string]: Peer };
  sessionIssues: SessionIssues;
  filteredPeers: { [key: string]: Peer };
}

interface SessionIssues {
  [key: string]: string[] | undefined;
}

const headerKeyToName: Record<string, string> = {
  name: "Peer Name",
  joined_at: `Start Time (${zoneAbbr})`,
  left_at: `End Time (${zoneAbbr})`,
  duration: "Peer Duration",
};

const SessionPeersTable: React.FC<Props> = ({
  isSessionLive,
  isSearch,
  loading,
  peers,
  sessionIssues,
  filteredPeers,
}) => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState<{ [key: string]: Peer }>({});
  const [pageSize, setPageSize] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableHeader, setTableHeader] = useState<string[]>([]);

  const sortPeersBy = useSelector(
    (state: RootState) => state.sessions.sortPeersBy
  );

  useEffect(() => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const keys = Object.keys(filteredPeers);
    const slicedKeys = keys.slice(startIndex, endIndex);

    const newPeers: { [key: string]: Peer } = {};

    slicedKeys.forEach(key => {
      newPeers[key] = filteredPeers[key];
    });
    setPageData(newPeers);
  }, [filteredPeers, pageNumber, pageSize, pageCount]);

  useEffect(() => {
    const newPageCount = Math.ceil(
      Object.keys(filteredPeers).length / pageSize
    );
    setPageCount(newPageCount);
    setPageNumber(1);
  }, [filteredPeers, pageSize]);

  useEffect(() => {
    if (isSessionLive) {
      const filteredHeaders = headers.filter(header => header !== "Issues");
      setTableHeader(filteredHeaders);
    } else {
      setTableHeader(headers);
    }
  }, [isSessionLive]);

  const handleSort = (sortBy: string) => {
    let direction = sortPeersBy.direction;
    if (sortPeersBy.by === sortBy || sortBy === "") {
      if (sortBy !== "") {
        direction = direction === "asc" ? "desc" : "asc";
      }
    } else {
      direction = "asc";
    }
    if (sortBy === "") {
      sortBy = sortPeersBy.by;
    }
    dispatch(
      sortFilteredPeers(
        sortBy,
        direction,
        sortSessionPeerTableColumn(filteredPeers, sortBy, direction)
      )
    );
  };

  return (
    <>
      <div className="table-responsive border-[1px] border-border-default rounded-lg">
        {isEmpty(filteredPeers) && isSearch && !loading ? (
          <NoResults
            title="No peers found"
            subtitle="Ensure that the applied filters are correct."
          />
        ) : (
          <Table
            tableHeaders={tableHeader}
            compact
            className="table table-borderless mb-0 mt-1"
            noHoverEffect
            hideBottomBorders={false}
            centerFinalColumn={false}
            highlightAll={false}
            sortingFunctions={{
              "Peer Name": () => {
                handleSort("name");
              },
              [`Start Time (${zoneAbbr})`]: () => {
                handleSort("joined_at");
              },
              [`End Time (${zoneAbbr})`]: () => {
                handleSort("left_at");
              },
              "Peer Duration": () => {
                handleSort("duration");
              },
            }}
            sortingHeader={{
              [headerKeyToName[sortPeersBy.by]]: sortPeersBy.direction,
            }}
          >
            {!isEmpty(pageData) &&
              Object.keys(pageData).map((peerId, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="flex gap-3 items-center whitespace-nowrap">
                        <TruncatedColumn
                          id={peers[peerId].name || "-"}
                          linkExist={false}
                          link=""
                          onClick={() => {
                            return;
                          }}
                        />
                        <CopyButton
                          value={peers[peerId].name || "-"}
                          componentId="sessions.details.page"
                          btnId="peer.id"
                          buttonCSS={{ marginLeft: "$2" }}
                        />
                      </div>
                    </td>
                    <td>
                      <Text variant="sm" css={{ color: "$on_surface_high" }}>
                        {peers[peerId].role || "-"}
                      </Text>
                    </td>
                    <td>
                      <div className="flex gap-3 items-center whitespace-nowrap">
                        <TruncatedColumn
                          id={peerId}
                          link={`/session-details/peer/${peerId}`}
                          linkExist={
                            peers[peerId].role !== INTERNAL_RECORDER_ROLE &&
                            !isSessionLive
                          }
                          onClick={() => {
                            return;
                          }}
                        />
                        <CopyButton
                          value={peerId}
                          componentId="sessions.details.page"
                          btnId="peer.id"
                          buttonCSS={{ marginLeft: "$2" }}
                        />
                      </div>
                    </td>
                    <td title={peers[peerId].user_id || "-"}>
                      <div className="flex gap-3 items-center whitespace-nowrap">
                        <TruncatedColumn
                          id={
                            peers[peerId].user_id ? peers[peerId].user_id : "-"
                          }
                          link=""
                          linkExist={false}
                          onClick={() => {
                            return;
                          }}
                        />
                        <CopyButton
                          value={peers[peerId].user_id || ""}
                          componentId="sessions.details.page"
                          btnId="user.id"
                          buttonCSS={{ marginLeft: "$2" }}
                        />
                      </div>
                    </td>
                    {!isSessionLive ? (
                      <td>
                        <div className="ankit flex gap-3 items-center whitespace-nowrap">
                          {sessionIssues[peerId] && (
                            <SessionIssueColumn
                              peerId={peerId}
                              issues={sessionIssues[peerId]!}
                            />
                          )}
                        </div>
                      </td>
                    ) : null}
                    <td>
                      <Text variant="sm" css={{ color: "$on_surface_high" }}>
                        {formattedDate(peers[peerId].joined_at)}
                      </Text>
                    </td>
                    <td>
                      <Text variant="sm" css={{ color: "$on_surface_high" }}>
                        {formattedDate(peers[peerId].left_at)}
                      </Text>
                    </td>
                    <td>
                      <Text variant="sm" css={{ color: "$on_surface_high" }}>
                        {secondsToHMS(peers[peerId].duration)}
                      </Text>
                    </td>
                  </tr>
                );
              })}
          </Table>
        )}
      </div>
      <TablePagination
        pageSize={pageSize}
        pageCount={pageCount}
        pageNumber={pageNumber}
        setPageNumber={pageNumber => setPageNumber(pageNumber)}
        setPageSize={pageSize => setPageSize(pageSize)}
        totalSize={Object.keys(filteredPeers).length}
        dataCategory="peers"
      />
    </>
  );
};

export default SessionPeersTable;
