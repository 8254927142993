import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { RootState } from "src/store/reducers";
import { fetchSessionHealth } from "src/store/sessions/actions";
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  NoEntryIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";
import SessionCQSChart from "../components/charts/sessionCQSChart";
import { SessionIssues } from "../components/SessionIssues";
import "react-loading-skeleton/dist/skeleton.css";
type Props = {
  sessionId: string;
};

const infoFlexCss = {
  flex: "1 1 0",
  flexWrap: "wrap",
  w: "100%",
};
// eslint-disable-next-line complexity
const SessionHealth = ({ sessionId }: Props) => {
  const dispatch = useDispatch();
  const fetchSessionHealthStatus = useSelector(
    (state: RootState) => state.sessions.fetchSessionHealthStatus
  );
  const sessionMosScore = useSelector(
    (state: RootState) => state.sessions.sessionMosScore
  );
  const sessionDetails = useSelector(
    (state: RootState) => state.sessions.sessionDetails
  );

  const sessionIssues = useSelector(
    (state: RootState) => state.sessions.sessionIssues
  );
  const isSessionLive = useSelector(
    (state: RootState) => state.sessions.derivedStates.isSessionLive
  );
  const [showSessionHealth, setShowSessionHealth] = useState(true);

  useEffect(() => {
    if (sessionId && !isSessionLive && typeof isSessionLive === "boolean") {
      dispatch(fetchSessionHealth(sessionId));
    }
  }, [dispatch, isSessionLive, sessionId]);
  if (isSessionLive || typeof isSessionLive !== "boolean") {
    return <></>;
  }
  const sessionMongoPeers = Object.keys(sessionDetails?.peers || {});
  return (
    <Flex
      direction="column"
      css={{
        w: "100%",
        border: "$space$px solid $border_default",
        r: "$1",
      }}
    >
      <Flex
        justify="between"
        align="center"
        onClick={() => setShowSessionHealth(!showSessionHealth)}
        css={{
          cursor: "pointer",
          px: "$10",
          py: "$9",
        }}
      >
        <Text
          variant="sub2"
          css={{
            fontWeight: "$semiBold",
          }}
        >
          Session Health
        </Text>

        <Flex>
          <LargeIcon>
            <ChevronDownIcon
              style={{
                transform: showSessionHealth ? "rotate(180deg)" : "none",
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </LargeIcon>
        </Flex>
      </Flex>
      <Flex
        css={{
          overflow: "hidden",
          visibility: showSessionHealth ? "visible" : "hidden",
          transition:
            "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
          maxHeight: showSessionHealth ? "700px" : "0",
        }}
      >
        <Flex
          direction="column"
          css={{
            h: "208px",
            w: "232px",
            borderTop: "$space$px solid $border_default",
            borderRight: "$space$px solid $border_default",
            justifyContent: "center",
          }}
        >
          {fetchSessionHealthStatus === API_CALL_STATE.FAILED ? (
            <Flex
              css={{
                ...infoFlexCss,
                r: "0px",
                py: "$8",
                px: "$10",
              }}
            >
              <Flex
                css={{
                  rowGap: "$md",
                  minHeight: "208px",
                  w: "100%",
                  textAlign: "center",
                }}
                justify="center"
                align="center"
                direction="column"
              >
                <Flex css={{ c: "$alert_error_default" }}>
                  <LargeIcon>
                    <AlertTriangleIcon />
                  </LargeIcon>
                </Flex>
                <Flex direction="column">
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$on_surface_medium",
                    }}
                  >
                    We couldn&apos;t load details for this session.
                  </Text>
                </Flex>
                <Button
                  icon
                  variant="standard"
                  css={{ p: "$3 $4", r: "$0" }}
                  onClick={() => openDevrevWidget(true)}
                >
                  <SmallIcon>
                    <SupportIcon />
                  </SmallIcon>
                  <Text variant="caption">Report Issue</Text>
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex direction="column">
              {fetchSessionHealthStatus === API_CALL_STATE.DONE ? (
                sessionMosScore !== null &&
                typeof sessionMosScore === "number" ? (
                  <SessionCQSChart score={sessionMosScore} />
                ) : (
                  <Flex
                    direction="column"
                    css={{
                      rowGap: "$md",
                      minHeight: "208px",
                      w: "100%",
                      textAlign: "center",
                      r: "0px",
                      py: "$8",
                      px: "$10",
                    }}
                    justify="center"
                    align="center"
                  >
                    <Flex css={{ c: "$on_surface_low" }}>
                      <LargeIcon>
                        <NoEntryIcon width={40} height={40} />
                      </LargeIcon>
                    </Flex>
                    <Flex direction="column">
                      <Text variant="caption" css={{ mb: "$2" }}>
                        No Session CQS Data
                      </Text>
                      <Text
                        variant="caption"
                        css={{
                          fontWeight: "$regular",
                          c: "$on_surface_medium",
                        }}
                      >
                        Please contact support for further details
                      </Text>
                    </Flex>
                  </Flex>
                )
              ) : (
                <Box
                  css={{
                    minHeight: "208px",
                    r: "0px",
                    py: "$8",
                    px: "$10",
                  }}
                >
                  <Skeleton width="100%" height="176px" />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
        <Flex
          direction="column"
          css={{ h: "208px", w: "calc(100% - 232px)", overflow: "auto" }}
        >
          {fetchSessionHealthStatus === API_CALL_STATE.FAILED ? (
            <Flex
              css={{
                ...infoFlexCss,
                py: "$8",
                px: "$10",
              }}
            >
              <Flex
                css={{
                  rowGap: "$md",
                  h: "100%",
                  w: "100%",
                  textAlign: "center",
                }}
                justify="center"
                align="center"
                direction="column"
              >
                <Flex css={{ c: "$alert_error_default" }}>
                  <LargeIcon>
                    <AlertTriangleIcon />
                  </LargeIcon>
                </Flex>
                <Flex direction="column">
                  <Text variant="sub2" css={{ mb: "$2" }}>
                    Something went wrong
                  </Text>
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$on_surface_medium",
                    }}
                  >
                    We couldn&apos;t load issue breakdown of peers for this
                    session.
                  </Text>
                </Flex>
                <Button
                  icon
                  variant="standard"
                  css={{ p: "$3 $4", r: "$0" }}
                  onClick={() => openDevrevWidget(true)}
                >
                  <SmallIcon>
                    <SupportIcon />
                  </SmallIcon>
                  <Text variant="caption">Report Issue</Text>
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex direction="column" css={{ w: "100%", h: "100%" }}>
              {fetchSessionHealthStatus === API_CALL_STATE.DONE ? (
                !isEmpty(sessionIssues) && !isEmpty(sessionDetails) ? (
                  <SessionIssues
                    sessionMongoPeers={sessionMongoPeers}
                    totalNoOfPeers={
                      parseInt(sessionDetails?.peer_count || "0") || 0
                    }
                    sessionIssuesData={sessionIssues}
                  />
                ) : (
                  <Flex
                    direction="column"
                    css={{
                      rowGap: "$md",
                      minHeight: "208px",
                      borderTop: "$space$px solid $border_default",
                      w: "100%",
                      textAlign: "center",
                    }}
                    justify="center"
                    align="center"
                  >
                    <Flex css={{ c: "$on_surface_low" }}>
                      <LargeIcon>
                        <NoEntryIcon />
                      </LargeIcon>
                    </Flex>
                    <Flex direction="column">
                      <Text variant="sub2" css={{ mb: "$2" }}>
                        No Session Issue Details
                      </Text>
                      <Text
                        variant="caption"
                        css={{
                          fontWeight: "$regular",
                          c: "$on_surface_medium",
                          w: "20rem",
                        }}
                      >
                        Please contact support for further details.
                      </Text>
                    </Flex>
                  </Flex>
                )
              ) : (
                <Box
                  css={{
                    minHeight: "208px",
                    borderTop: "$space$px solid $border_default",
                    py: "$8",
                    px: "$10",
                  }}
                >
                  <Skeleton width="100%" height={176} />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SessionHealth;
