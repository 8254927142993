import React from "react";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { peerAnalyticsPublishType } from "src/types/analytics";
import { policyTemplateType } from "src/types/policyTypes";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/roomkit-react";

type Props = {
  policy: policyTemplateType;
  role: string;
  publishData: peerAnalyticsPublishType;
};

const getRTTSentiment = (rtt: number) => {
  if (rtt < 100) {
    return { text: "Good", color: "$alert_success" };
  }
  if (rtt < 300) {
    return { text: "Fair", color: "$alert_warning" };
  }
  return { text: "Bad", color: "$alert_error_default" };
};

function TrackMessage({
  text,
  val,
  tooltip = "",
}: {
  text: string;
  val: string;
  tooltip?: string;
}) {
  const value = parseInt(val) === 0 ? "0" : val;

  const sentiment = getRTTSentiment(Number(val));
  return (
    <Flex
      css={{
        p: "$8 $10",
      }}
      justify="start"
      direction="column"
    >
      <Flex align="center" css={{ gap: "$2" }}>
        <Text variant="caption" css={{ c: "$on_surface_low" }}>
          {text}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ r: "$0", w: "$80" }}
          >
            <Flex css={{ c: "$on_surface_low" }}>
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Text
        variant="body2"
        css={{ c: sentiment.color, mt: "$2", fontWeight: "$regular" }}
      >
        {`${value}ms - ${sentiment.text}`}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function PublisherRoundTripMetrics({ publishData }: Props) {
  const mergedVideoLayers = (
    videoLayers: { timestamp: string; rtt: number }[][]
  ) => {
    if (isEmpty(videoLayers)) {
      return [];
    }
    const result = [];
    for (let i = 0; i < videoLayers[0].length; i++) {
      //calculate max on the same index across all the arrays inside videoLayers
      let maxRTT = 0;
      for (let j = 0; j < videoLayers.length; j++) {
        if (
          videoLayers?.[j]?.[i] !== undefined &&
          videoLayers?.[j]?.[i] !== null
        ) {
          maxRTT = Math.max(maxRTT, videoLayers[j][i].rtt);
        }
      }
      result.push({
        timestamp: videoLayers[0][i].timestamp,
        rtt: maxRTT,
      });
    }
    return result;
  };
  const videoLayer = mergedVideoLayers(
    (Object.values(publishData?.video || {}) || []) as {
      timestamp: string;
      rtt: number;
    }[][]
  );
  const screenLayer = publishData?.screen || [];
  const audioLayer = publishData?.audio || [];
  const calulateAverageRTTForLayer = (
    data: { timestamp: string; rtt: number }[]
  ) => {
    if (isEmpty(data)) {
      return 0;
    }
    let totalRTT = 0;
    let totalDuration = 0;

    for (let i = 0; i < data.length - 1; i++) {
      const diff =
        (new Date(data[i + 1].timestamp).getTime() -
          new Date(data[i].timestamp).getTime()) /
        1000;
      if (Number.isFinite(data?.[i]?.rtt) && data?.[i]?.rtt > 0) {
        totalRTT += data[i].rtt * diff;
      }

      totalDuration += diff;
    }
    if (totalDuration === 0) {
      return Number.isFinite(data?.[0]?.rtt) ? data?.[0]?.rtt : 0;
    }
    return totalRTT / totalDuration;
  };

  return (
    <Flex
      css={{
        borderTop: "$space$px solid $border_default",
        borderLeft: "$space$px solid $border_default",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      {isEmpty(videoLayer) ? (
        <></>
      ) : (
        <TrackMessage
          text="Average Video RTT"
          tooltip="Total Round Trip Time calculated across all the simulcast layers excluding screenshare"
          val={calulateAverageRTTForLayer(
            videoLayer as { timestamp: string; rtt: number }[]
          ).toFixed(2)}
        />
      )}
      {isEmpty(screenLayer) ? (
        <></>
      ) : (
        <TrackMessage
          text="Average Screen RTT"
          val={calulateAverageRTTForLayer(
            screenLayer as { timestamp: string; rtt: number }[]
          ).toFixed(2)}
        />
      )}
      {isEmpty(audioLayer) ? (
        <></>
      ) : (
        <TrackMessage
          text="Average Audio RTT"
          val={calulateAverageRTTForLayer(
            audioLayer as { timestamp: string; rtt: number }[]
          ).toFixed(2)}
        />
      )}
      <Flex
        css={{
          p: "$8 $10",
        }}
        justify="start"
        direction="column"
      >
        <Flex align="center" css={{ gap: "$2" }}>
          <Text variant="caption" css={{ c: "$on_surface_low" }}>
            Threshold Value
          </Text>
        </Flex>
        <Text
          variant="body2"
          css={{ c: "$on_surface_medium", mt: "$2", fontWeight: "$regular" }}
        >
          300ms
        </Text>
      </Flex>
    </Flex>
  );
}
