import React, { useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Container } from "reactstrap";
import moment from "moment";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import Tag from "src/components/Tag/Tag";
import { Dropdown, Flex, Text } from "@100mslive/roomkit-react";
import WorkspaceFilter from "./containers/workspaceFilter";
import WorkspacePeerInsights from "./containers/workspacePeersInsights";
import WorkspaceRecordingsInsights from "./containers/workspaceRecordingsInsights";
import WorkspaceSessionsInsights from "./containers/workspaceSessionsInsights";
import { WorkspaceUsageInsights } from "./containers/workspaceUsageInsights";
import { WORKSPACE_RECORDINGS_TYPE_BUCKETS } from "./constants";
const Index = () => {
  const todayDate = moment.utc("04:30", "HH:mm");

  const [groupByRecordingsType, setGroupByRecordingsType] = useState(
    WORKSPACE_RECORDINGS_TYPE_BUCKETS.all.key
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Flex css={{ mb: "$4", w: "100%" }} justify="between">
            <Flex align="center">
              <Text variant="h5" css={{ c: "$on_surface_high" }}>
                Insights
              </Text>
              <Tag
                type="highlight"
                css={{
                  fontSize: "$tiny",
                  r: "$0",
                  py: "$2",
                  px: "$4",
                  ml: "$4",
                }}
                variant="overline"
                label="BETA"
              />
            </Flex>
            <Flex justify="center" direction="column">
              <Text
                variant="caption"
                css={{ c: "$on_surface_low", fontWeight: "$regular" }}
              >
                Data available for last 30 days
              </Text>

              <Text
                variant="caption"
                css={{ c: "$on_surface_low", fontWeight: "$regular" }}
              >
                Data updated at {todayDate.format("hh:mm A z")} everyday
              </Text>
            </Flex>
          </Flex>

          <WorkspaceFilter />
          <SkeletonTheme baseColor="#11131A" highlightColor="#1C2027">
            <Flex css={{ mt: "$10" }}>
              <TextAndSubtext
                text="Sessions"
                subtext="Review session usage and troubleshoot."
              />
            </Flex>
            <WorkspaceSessionsInsights />
            <Flex>
              <TextAndSubtext
                text="Peers"
                subtext="Review peer usage and troubleshoot."
              />
            </Flex>
            <WorkspacePeerInsights />
            {/* <Flex>
              <TextAndSubtext
                text="Issues"
                subtext="Review issues and where they come from"
              />
            </Flex>
            <WorkspaceIssuesInsights /> */}
            <Flex css={{ w: "100%" }} align="center" justify="between">
              <TextAndSubtext
                text="Recordings"
                subtext="Review recordings usage and troubleshoot."
              />
              <Flex align="center">
                <Text
                  variant="caption"
                  css={{
                    mr: "$4",
                    fontWeight: "$regular",
                    c: "$on_surface_low",
                  }}
                >
                  Group By
                </Text>
                <ConfigSingleSelect
                  inputText={
                    WORKSPACE_RECORDINGS_TYPE_BUCKETS[
                      groupByRecordingsType as keyof typeof WORKSPACE_RECORDINGS_TYPE_BUCKETS
                    ].name || "Please select Group By"
                  }
                  inputTextCss={{
                    fontWeight: "$regular",
                    fontSize: "$xs",
                    width: "180px",
                    textAlign: "left",
                    mr: "$2",
                  }}
                  content={
                    <>
                      {Object.keys(WORKSPACE_RECORDINGS_TYPE_BUCKETS).map(
                        (option: string) => (
                          <Dropdown.Item
                            key={option}
                            css={{ p: "$4 $8" }}
                            onClick={() => {
                              setGroupByRecordingsType(option);
                            }}
                          >
                            <Text variant="caption">
                              {
                                WORKSPACE_RECORDINGS_TYPE_BUCKETS[
                                  option as keyof typeof WORKSPACE_RECORDINGS_TYPE_BUCKETS
                                ].name
                              }
                            </Text>
                          </Dropdown.Item>
                        )
                      )}
                    </>
                  }
                />
              </Flex>
            </Flex>
            <WorkspaceRecordingsInsights
              groupByRecordingsType={groupByRecordingsType}
            />
            <Flex>
              <TextAndSubtext
                text="Total Usage"
                subtext="Review total usage minutes and troubleshoot."
              />
            </Flex>
            <WorkspaceUsageInsights />
          </SkeletonTheme>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;

const TextAndSubtext = ({
  text,
  subtext,
}: {
  text: string;
  subtext: string;
}) => {
  return (
    <Flex direction="column">
      <Text variant="h6" css={{ c: "$on_surface_high" }}>
        {text}
      </Text>
      <Text
        variant="caption"
        css={{ fontWeight: "$regular", c: "$on_surface_low" }}
      >
        {subtext}
      </Text>
    </Flex>
  );
};
