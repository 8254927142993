import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import Line from "src/components/Common/Line";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { RootState } from "src/store/reducers";
import { fetchSessionInformation } from "src/store/sessions/actions";
import { Stats } from "src/types/sessions";
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import SessionInformation from "../components/SessionInformation";
import SessionStatsInformation from "../components/SessionStatsInformation";

type Props = { sessionId: string };

const infoFlexCss = {
  r: "$1",
  flex: "1 1 0",
  flexWrap: "wrap",
  gap: "$10",
  w: "100%",
  rowGap: "$10",
};
export default function SessionInfo({ sessionId }: Props) {
  const [showSessionInfo, setShowSessionInfo] = useState(true);
  const dispatch = useDispatch();
  const sessionDetails = useSelector(
    (state: RootState) => state.sessions.sessionDetails
  );
  const fetchSessionDetailsStatus = useSelector(
    (state: RootState) => state.sessions.fetchSessionDetailsStatus
  );
  useEffect(() => {
    if (sessionId) {
      dispatch(fetchSessionInformation(sessionId));
    }
  }, [sessionId, dispatch]);
  return (
    <Flex
      direction="column"
      css={{
        w: "100%",
        border: "$space$px solid $border_default",
        r: "$1",
        px: "$10",
        py: "$9",
      }}
    >
      <Flex
        justify="between"
        align="center"
        onClick={() => setShowSessionInfo(!showSessionInfo)}
        css={{
          cursor: "pointer",
        }}
      >
        {fetchSessionDetailsStatus === API_CALL_STATE.IN_PROGRESS ? (
          <Skeleton width={160} height="100%" />
        ) : (
          <Text
            variant="sub2"
            css={{
              fontWeight: "$semiBold",
            }}
          >
            Session Information
          </Text>
        )}
        <Flex>
          <LargeIcon>
            <ChevronDownIcon
              style={{
                transform: showSessionInfo ? "rotate(180deg)" : "none",
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </LargeIcon>
        </Flex>
      </Flex>
      <Flex
        css={{
          overflow: "hidden",
          visibility: showSessionInfo ? "visible" : "hidden",
          transition:
            "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
          maxHeight: showSessionInfo ? "700px" : "0",
        }}
        direction="column"
      >
        {fetchSessionDetailsStatus === API_CALL_STATE.FAILED ? (
          <Flex css={{ ...infoFlexCss, py: "$8", px: "$10" }}>
            <Flex
              css={{
                rowGap: "$md",
                minHeight: "240px",
                w: "100%",
                textAlign: "center",
              }}
              justify="center"
              align="center"
              direction="column"
            >
              <Flex css={{ c: "$alert_error_default" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column">
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    c: "$on_surface_medium",
                    w: "20rem",
                  }}
                >
                  We couldn&apos;t load details for this session.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex direction="column">
            <Flex css={{ ...infoFlexCss, mt: "$8" }}>
              {fetchSessionDetailsStatus === API_CALL_STATE.DONE ? (
                <SessionInformation sessionData={sessionDetails} />
              ) : (
                <Skeleton width={500} height={44} />
              )}
            </Flex>
            <Line />
            <Flex css={{ ...infoFlexCss }}>
              {fetchSessionDetailsStatus === API_CALL_STATE.DONE ? (
                <SessionStatsInformation
                  sessionStatsData={(sessionDetails?.stats || {}) as Stats}
                />
              ) : (
                <Skeleton width={600} height={44} />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
