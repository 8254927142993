import React from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { SessionMinutesBreakdown } from "src/types/insightsTypes";
import { Flex } from "@100mslive/roomkit-react";
import { addAbbreviationToNumber } from "../../helpers";

const BreakdownBucket = {
  "0-5": {
    title: "Less than 5 mins",
    end: 5,
    start: 0,
  },
  "5-10": {
    title: "5-10 mins",
    end: 10,
    start: 6,
  },
  "10-30": {
    title: "10-30 mins",
    end: 30,
    start: 11,
  },
  "30-60": {
    title: "30-60 mins",
    end: 60,
    start: 31,
  },
  "60-MAX": {
    title: "More than 60 mins",
    start: 61,
    end: Number.MAX_SAFE_INTEGER,
  },
} as Record<string, { title: string; start: number; end: number }>;

type echartsOptions = echarts.EChartsOption;
export const RecordingsBreakdownBriefChart = ({
  data,
  loading,
}: {
  data: SessionMinutesBreakdown;
  loading: boolean;
}) => {
  const getBucketData = (data: SessionMinutesBreakdown) => {
    const arr = Object.keys(data).map(key => parseInt(key));
    const MaxDuration = arr.reduce((a, b) => Math.max(a, b), 0);
    const data_map = {} as Record<string, number>;

    Object.keys(BreakdownBucket).map((key: string) => {
      const bucket = BreakdownBucket[key];
      let totalCount = 0;

      const end =
        bucket.end === Number.MAX_SAFE_INTEGER ? MaxDuration : bucket.end;
      for (let i = bucket.start; i <= end; i++) {
        if (data[i]) {
          totalCount += data[i];
        }
      }
      data_map[key] = totalCount;
      return totalCount;
    });
    return data_map;
  };

  const final_data = getBucketData(data);

  const eventLogChartoptions: echartsOptions = {
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: "axis", // Trigger tooltip on item (icon) hover
      backgroundColor: "#1E232A",
      borderRadius: 4,
      padding: 4,
      borderColor: "#1E232A",
      textStyle: {
        color: "#F5F9FF",
      },
      show: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: (value: number) => {
          return `${addAbbreviationToNumber(value, 0)}`;
        },
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
    },
    grid: {
      top: "5%",
      left: "30%",
      right: "5%",
      bottom: "10%",
    },

    yAxis: {
      type: "category",
      axisLabel: {
        show: true,
      },

      data: Object.keys(final_data)
        .sort((a, b) => {
          return BreakdownBucket[a].start > BreakdownBucket[b].start ? 1 : -1;
        })
        .map(key => BreakdownBucket[key].title),
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
    },

    series: [
      {
        type: "bar",
        data: Object.keys(final_data)
          .sort((a, b) => {
            return BreakdownBucket[a].start > BreakdownBucket[b].start ? 1 : -1;
          })
          .map(key => {
            return final_data[key];
          }),
        itemStyle: {
          color: "#2672ED",
        },
        markLine: {
          zlevel: 0,
          label: {
            show: false,
          },
          silent: true,
          symbol: ["none", "none"],
          lineStyle: {
            color: "#2C333F",
            type: "solid",
          },
        },
      },
    ],
  };

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...eventLogChartoptions }}
        loading={loading}
        className="mos-chart"
        style={{ height: "280px", width: "auto" }}
      />
    </Flex>
  );
};
