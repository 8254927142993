/* eslint-disable complexity */
import React from "react";
import { isEmpty } from "lodash";
import { peerAnalyticsType } from "src/types/analytics";
import { MobileIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";
import { getPeerPlatformInfoIcon } from "../helpers/GetPeerPlatformIcons";

type Props = { peerPlatformData: peerAnalyticsType };

export const peerPlatformInfo = {
  Platform: "platform",
  "Operating System": "os",
  Browser: "browser",
  Framework: "framework",
  "SDK Version": "sdk_version",
};

const PeerPlatformDetails = ({ peerPlatformData }: Props) => {
  const listOfInfo = Object.keys(peerPlatformInfo);
  if (isEmpty(peerPlatformData)) {
    return <></>;
  }

  return (
    <>
      {listOfInfo.map((key: string) => {
        const modifiedKeyForBrowser = "browser_type";
        const modifiedKeyForOS = "os_type";

        const Icon = getPeerPlatformInfoIcon({
          key: key as keyof typeof peerPlatformInfo,
          val: peerPlatformData[
            key !== "Browser"
              ? key !== "Operating System"
                ? (peerPlatformInfo[
                    key as keyof typeof peerPlatformInfo
                  ] as keyof peerAnalyticsType)
                : (modifiedKeyForOS as keyof peerAnalyticsType)
              : (modifiedKeyForBrowser as keyof peerAnalyticsType)
          ]?.toString(),
        });
        let val =
          peerPlatformData?.[
            peerPlatformInfo[
              key as keyof typeof peerPlatformInfo
            ] as keyof peerAnalyticsType
          ]?.toString() || "-";
        if (key === "Framework" && peerPlatformData?.["framework"] !== null) {
          val = val.concat(` ${peerPlatformData?.["framework_version"]}`);
        }
        if (key === "Framework" && !peerPlatformData?.show_framework) {
          return <></>;
        }
        if (key === "Browser" && !peerPlatformData?.show_browser) {
          return (
            <Flex direction="column">
              <Text
                variant="tiny"
                css={{
                  color: "$on_surface_medium",
                  fontWeight: "$semiBold",
                  textTransform: "uppercase",
                  mb: "$4",
                  letterSpacing: "1.5px",
                }}
              >
                Device Model
              </Text>
              <Flex justify="start" align="center">
                {" "}
                <Flex
                  css={{
                    bg: "$secondary_bright",
                    w: "$12",
                    h: "$12",
                    r: "$round",
                    p: "$2",
                  }}
                  justify="center"
                  align="center"
                >
                  <MobileIcon style={{ width: "32px", height: "32px" }} />
                </Flex>
                <Text
                  variant="body2"
                  css={{
                    fontWeight: "$regular",
                    ml: "$4",
                  }}
                >
                  {val}
                </Text>
              </Flex>
            </Flex>
          );
        }
        return (
          <Flex direction="column">
            {" "}
            <Text
              variant="tiny"
              css={{
                color: "$on_surface_medium",
                fontWeight: "$semiBold",
                textTransform: "uppercase",
                mb: "$4",
                letterSpacing: "1.5px",
              }}
            >
              {key}
            </Text>
            <Flex justify="start" align="center">
              {isEmpty(Icon) ? null : Icon}
              <Text
                variant="body2"
                css={{
                  ml: isEmpty(Icon) ? "$0" : "$4",
                }}
              >
                {val}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

export default PeerPlatformDetails;
