import React from "react";
import * as echarts from "echarts";
import { capitalize, isEmpty } from "lodash";
import { default as EchartsWrapper } from "src/components/Echarts";
import { Flex } from "@100mslive/roomkit-react";
import {
  WORKSPACE_INSIGHTS_COLOR_CODES,
  WORKSPACE_PEER_PLATFORM_LIST,
} from "../../constants";

type echartsOptions = echarts.EChartsOption;
export const ActivePeersChart = ({
  data,
  loading,
  filter,
  groupBy,
}: {
  filter: string;
  data: Record<string, Record<string, number>>;
  loading: boolean;
  groupBy: string;
}) => {
  const isDaily = filter === "daily";
  if (isEmpty(data)) {
    return <></>;
  }
  const isGroupByRole = groupBy === "role";

  const getDataConfig = (data: Record<string, Record<string, number>>) => {
    return Object.keys(data)
      .sort()
      .map((item, index) => {
        const obj = data[item];
        const rem = index % 10;
        const lineColorHEX = WORKSPACE_INSIGHTS_COLOR_CODES[rem];
        const name = isGroupByRole
          ? capitalize(item)
          : WORKSPACE_PEER_PLATFORM_LIST[
              item as keyof typeof WORKSPACE_PEER_PLATFORM_LIST
            ].name;
        return {
          name: name,
          type: "line",
          data: Object.keys(obj)
            .sort((a, b) => a.localeCompare(b)) // Sort the keys as strings
            .map(key => {
              return {
                value: [key, obj[key as keyof typeof obj]],
              };
            }),
          lineStyle: {
            type: "solid",
            color: lineColorHEX,
          },
          itemStyle: {
            color: lineColorHEX,
          },
          zlevel: 1,
          symbol: "none",
          markLine: {
            zlevel: 0,
            label: {
              show: false,
            },
            silent: true,
            symbol: ["none", "none"],
            lineStyle: {
              color: lineColorHEX,
              type: "solid",
            },
          },
        };
      });
  };

  const getLegendData = (data: Record<string, Record<string, number>>) => {
    return Object.keys(data)
      .sort()
      .map((item, index) => {
        const rem = index % 10;
        const lineColorHEX = WORKSPACE_INSIGHTS_COLOR_CODES[rem];
        const name = isGroupByRole
          ? capitalize(item)
          : WORKSPACE_PEER_PLATFORM_LIST[
              item as keyof typeof WORKSPACE_PEER_PLATFORM_LIST
            ].name;
        return {
          name: name,
          itemStyle: {
            color: lineColorHEX,
          },
        };
      });
  };

  const eventLogChartoptions: echartsOptions = {
    legend: {
      left: "3%",
      z: 2,
      type: "plain",
      orient: "horizontal",
      show: true,
      icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
      textStyle: { color: "#FFFFFF" },
      data: getLegendData(data),
    },
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: "axis", // Trigger tooltip on item (icon) hover
      backgroundColor: "#1E232A",
      borderRadius: 4,
      padding: 4,
      borderColor: "#1E232A",
      textStyle: {
        color: "#F5F9FF",
      },
      show: true,
    },
    xAxis: {
      type: "time",
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
      axisLabel: {
        formatter: function (value: number, index: number) {
          return isDaily
            ? "{MMM} {dd}"
            : (index - 1) % 7 === 0
            ? //@ts-ignore
              formatWeekRange(new Date(value).toString())
            : "";
        },
        showMaxLabel: true,
        showMinLabel: true,
        alignMaxLabel: isDaily ? "center" : "right",
      },
    },

    grid: {
      top: "30%",
      left: "10%",
      right: "5%",
      bottom: "10%",
    },

    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },

      min: 0,
    },
    series: getDataConfig(data) as echartsOptions["series"],
  };

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <>
        {/* @ts-ignore */}
        <EchartsWrapper
          options={{ ...eventLogChartoptions }}
          loading={loading}
          className="active-peers-chart"
          style={{ height: "300px", width: "auto" }}
        />
      </>
    </Flex>
  );
};
