import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import InspectButton from "src/components/Common/InspectButton";
import { history } from "src/helpers";
import { ChevronLeftIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import SessionPeersTable from "./containers/SessionDetailsTables";
import SessionHealth from "./containers/sessionHealth";
import SessionInfo from "./containers/sessionInfo";
import SessionTimeline from "./containers/sessionTimeline";

export default function SessionDetails() {
  const { sessionId = "" } = useParams<{ sessionId: string }>();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <SkeletonTheme baseColor="#11131A" highlightColor="#1C2027">
            <Flex direction="column">
              <Flex css={{ w: "100%" }} justify="between" align="center">
                <Flex justify="start" align="center" css={{ gap: "$md" }}>
                  <Box
                    css={{ cursor: "pointer", c: "$on_surface_medium" }}
                    onClick={() => history.goBack()}
                  >
                    <LargeIcon>
                      <ChevronLeftIcon />
                    </LargeIcon>
                  </Box>

                  <Text variant="h5">Session Details</Text>
                </Flex>
                <InspectButton
                  componentId="sessions.details.page"
                  btnId="inspect.room"
                  link={`/events-inspector?id=${sessionId}`}
                />
              </Flex>
              <Flex direction="column" css={{ mt: "$10", gap: "$10" }}>
                <SessionInfo sessionId={sessionId} />
                <SessionTimeline sessionId={sessionId} />
                <SessionHealth sessionId={sessionId} />
                <SessionPeersTable />
              </Flex>
            </Flex>
          </SkeletonTheme>
        </Container>
      </div>
    </React.Fragment>
  );
}
