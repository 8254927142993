import React from "react";
import { Flex } from "@100mslive/roomkit-react";

interface LabelProps {
  text: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const IssueLabel: React.FC<LabelProps> = ({
  text,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Flex
      css={{
        c: "$on_surface_high",
        borderRadius: "$0",
        backgroundColor: "$secondary_dim",
        width: "fit-content",
      }}
      className="p-2 font-semibold cursor-pointer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}
    </Flex>
  );
};

export default IssueLabel;
