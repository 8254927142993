import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { Flex } from "@100mslive/roomkit-react";
import RoomDetails from "./containers/roomDetails";
import RoomSessionDetails from "./containers/roomSessionsDetails";

function Index() {
  const { roomId = "" } = useParams<{ roomId: string }>();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <SkeletonTheme baseColor="#11131A" highlightColor="#1C2027">
            <Flex direction="column">
              <Flex direction="column" css={{ mt: "$10", gap: "$10" }}>
                <RoomDetails roomId={roomId} />
                <RoomSessionDetails roomId={roomId} />
              </Flex>
            </Flex>
          </SkeletonTheme>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Index;
