import React from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import TitleValue from "src/components/Common/TitleValue";
import {
  RECORDING_LOG_COMPONENT_ID,
  SESSION_DETAILS_TABS,
} from "src/constants";
import { setSessionDetailsActiveTab } from "src/store/sessions/actions";
import { Session } from "src/types/sessions";
import { ChevronRightIcon } from "@100mslive/react-icons";
import { Box, Text } from "@100mslive/roomkit-react";

type Props = { sessionData: Session };

const sessionInfoObject = {
  "Session ID": "id",
  "Room ID": "room_id",
};

const SessionInformation = ({ sessionData }: Props) => {
  const dispatch = useDispatch();
  const listOfInfo = Object.keys(sessionInfoObject);
  if (isEmpty(sessionData)) {
    return <></>;
  }
  return (
    <>
      {listOfInfo.map((key: string) => {
        let link;
        const val =
          sessionData?.[
            sessionInfoObject[
              key as keyof typeof sessionInfoObject
            ] as keyof Session
          ];
        if (!val) {
          return <> </>;
        }
        if (key === "Room ID") {
          link = `/room-details/${val}`;
        }

        return (
          <TitleValue
            key={key}
            title={key}
            link={link}
            value={val.toString()}
            btnId={sessionInfoObject[key as keyof typeof sessionInfoObject]}
            componentId={
              sessionInfoObject[key as keyof typeof sessionInfoObject]
            }
            copyButton={true}
            textCss={{
              fontFamily: "var(--user-font-roboto)",
              c: key !== "Room ID" ? "$textHightEmp" : "$primary_bright",
              "&:hover": {
                cursor: key === "Room ID" ? "pointer" : "",
                textDecoration: key === "Room ID" ? "underline" : "",
              },
            }}
          />
        );
      })}
      <Box>
        <Text
          variant="tiny"
          css={{
            color: "$on_surface_medium",
            fontWeight: "$semiBold",
            textTransform: "uppercase",
            mb: "$4",
            letterSpacing: "1.5px",
          }}
        >
          Status
        </Text>

        <Text
          variant="sm"
          css={{
            fontWeight: "$semiBold",
            color: sessionData?.["active"]
              ? "$alert_success"
              : "$alert_error_default",
          }}
        >
          {sessionData?.["active"] ? "Live" : "Ended"}
        </Text>
      </Box>
      <Box>
        <Text
          variant="tiny"
          css={{
            color: "$on_surface_medium",
            fontWeight: "$semiBold",
            textTransform: "uppercase",
            mb: "$4",
            letterSpacing: "1.5px",
          }}
        >
          Recording
        </Text>

        <Text
          variant="sm"
          css={{
            fontWeight: "$regular",
            color: "$primary_bright",
            cursor: "pointer",
          }}
          onClick={() => {
            window.history.replaceState(
              null,
              "",
              `?tab=${SESSION_DETAILS_TABS.RECORDING_LOG}`
            );
            dispatch(
              setSessionDetailsActiveTab(SESSION_DETAILS_TABS.RECORDING_LOG)
            );
            document
              .getElementById(RECORDING_LOG_COMPONENT_ID)
              ?.scrollIntoView();
          }}
        >
          {isEmpty(sessionData?.recording_logs) ? "No Assets" : "View Assets "}
          <ChevronRightIcon width="14" height="14" />
        </Text>
      </Box>
    </>
  );
};

export default SessionInformation;
