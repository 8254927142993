import React from "react";
import Credential from "src/components/Common/Credential";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import { API_CALL_STATE } from "src/constants";
import { roomRTMPStreamKeyType } from "src/types/roomCodeTypes";
import { AlertTriangleIcon } from "@100mslive/react-icons";
import { Box, Flex, Loading, Text } from "@100mslive/roomkit-react";

type Props = {
  roomId: string;
  templateStatus: string;
  fetchRTMPStreamStatus: string;
  roomStreamDetails: Record<string, roomRTMPStreamKeyType>;
};

//to be used only for set template flow and onboarding flow
export default function StreamDetailsForm({
  roomId,
  fetchRTMPStreamStatus,
  templateStatus,
  roomStreamDetails,
}: Props) {
  const CredentialsCSS = {
    css: {
      width: "50%",
      flexDirection: "column",
      maxWidth: "400px",
      mr: "$px",
      "& div": {
        w: "unset",
      },
    },
    inputCss: {
      border: "1px solid $border_bright",
      backgroundColor: "$surface_brighter",
      borderRadius: "$0",
      mr: "$px",
    },
    labelCss: {
      fontWeight: "$semiBold",
      color: "$on_surface_medium",
      fontSize: "$xs",
      marginBottom: "$xs",
    },
  };
  return (
    <Flex
      direction="column"
      align="start"
      css={{
        w: "100%",
        minWidth: 0,
        r: "$0",
        p: "$10",
        backgroundColor: "$surface_default",
      }}
    >
      {templateStatus === API_CALL_STATE.IN_PROGRESS ||
      fetchRTMPStreamStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ w: "100%", h: "100%" }} justify="center" align="center">
          <Loading size={72} />
        </Flex>
      ) : fetchRTMPStreamStatus === API_CALL_STATE.DONE ? (
        <Flex direction="column" css={{ w: "100%" }}>
          <Text variant="sub1" css={{ c: "$on_surface_high" }}>
            Input Stream Details
          </Text>
          <Text
            variant="body2"
            css={{ fontWeight: "$regular", c: "$on_surface_medium", mt: "$4" }}
          >
            Copy and paste the following details in your broadcasting software
            and start your stream to go live on 100ms. Read{" "}
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/live-streaming-rtmp-ingestion`}
            >
              <Text
                as="span"
                variant="body2"
                css={{
                  c: "$primary_default",
                  "&:hover": { c: "$primary_bright" },
                }}
              >
                this guide
              </Text>
            </a>{" "}
            on how to configure OBS studio for streaming.
          </Text>
          <Flex css={{ mt: "$10", gap: "$10" }}>
            <Credential
              {...CredentialsCSS}
              outlined
              readOnly
              label="RTMP Stream Key"
              value={roomStreamDetails?.[roomId]?.key}
            />
            <Credential
              {...CredentialsCSS}
              outlined
              readOnly
              label="RTMP URL"
              value={roomStreamDetails?.[roomId]?.url}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" css={{ w: "100%" }}>
          <Box css={{ c: "$alert_error_default" }}>
            <LargeIcon>
              <AlertTriangleIcon />
            </LargeIcon>
          </Box>
          <Text variant="sub1" css={{ c: "$on_surface_high" }}>
            Something went wrong
          </Text>
          <Text variant="caption" css={{ c: "$on_surface_medium" }}>
            Seems like something went wrong please try later using the RTMP
            generate CTA on room details page or create a new room using create
            room cta on dashboard home page
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
