import React from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { WORKSPACE_INSIGHTS_FILTER } from "src/constants";
import { WorkspaceSessionDetails } from "src/types/insightsTypes";
import { Flex } from "@100mslive/roomkit-react";
import { addAbbreviationToNumber, formatWeekRange } from "../../helpers";

type echartsOptions = echarts.EChartsOption;
export const ActiveSessionsChart = ({
  data,
  loading,
  filter,
}: {
  data: WorkspaceSessionDetails;
  loading: boolean;
  filter: string;
}) => {
  const isDaily = filter === WORKSPACE_INSIGHTS_FILTER.daily.toLowerCase();
  const eventLogChartoptions: echartsOptions = {
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: "axis", // Trigger tooltip on item (icon) hover
      backgroundColor: "#1E232A",
      borderRadius: 4,
      padding: 4,
      borderColor: "#1E232A",
      textStyle: {
        color: "#F5F9FF",
      },
      show: true,
    },
    xAxis: {
      type: "time",
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: function (value: number, index: number) {
          return isDaily
            ? "{MMM} {dd}"
            : (index - 1) % 7 === 0
            ? //@ts-ignore
              formatWeekRange(new Date(value).toString())
            : "";
        },
        showMaxLabel: true,
        alignMaxLabel: isDaily ? "center" : "right",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
    },

    grid: {
      top: "5%",
      left: "15%",
      right: "5%",
      bottom: "10%",
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value: number) => {
          return `${addAbbreviationToNumber(value, 0)}`;
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },

      min: 0,
    },

    series: [
      {
        type: "line",
        data: Object.keys(data).map(item => {
          return { value: [item, data?.[item]?.session_count] };
        }),
        lineStyle: {
          color: "#2672ED",
          type: "solid",
        },

        zlevel: 1,
        symbol: "none",
        markLine: {
          zlevel: 0,
          label: {
            show: false,
          },
          silent: true,
          symbol: ["none", "none"],
          lineStyle: {
            color: "#2C333F",
            type: "solid",
          },
        },
      },
    ],
  };

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...eventLogChartoptions }}
        loading={loading}
        className="mos-chart"
        style={{ height: "280px", width: "auto" }}
      />
    </Flex>
  );
};
