import React, { useState } from "react";
import { DownloadIcon, ExternalLinkIcon } from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";

export const RecordingLinkButton = ({
  data,
  assetId,
  roomId,
  fetchPresignedUrl,
  label,
  setAssetLabel,
  disabled,
}: {
  data: string;
  assetId: string;
  label: string;
  disabled: boolean;
  roomId: string;
  setAssetLabel: (assetType: string) => void;
  fetchPresignedUrl: ({
    roomId,
    assetId,
    data,
    download,
    setLoading,
  }: {
    setLoading: (value: boolean) => void;
    roomId: string;
    assetId: string;
    data: string;
    download?: boolean;
  }) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const isDownloadButton = label.includes("Download");

  return (
    <Flex>
      <a
        href={data}
        target=""
        rel="noreferrer noopener"
        onClick={e => {
          e.preventDefault();
        }}
      >
        <Text
          variant="body2"
          className="truncate w-56"
          css={{
            color: "$primary_bright",
          }}
        >
          <Button
            variant="standard"
            outlined={!isDownloadButton}
            icon
            loading={loading}
            disabled={disabled}
            onClick={() => {
              setAssetLabel(label);
              fetchPresignedUrl({
                roomId: roomId,
                assetId: assetId,
                data: data,
                setLoading: setLoading,
                download: label.includes("Download"),
              });
            }}
            css={{ color: "$on_surface_high", r: "$0" }}
          >
            {!disabled && isDownloadButton ? (
              <DownloadIcon
                style={{ color: "inherit", position: "relative", top: "-1px" }}
                height={20}
                width={20}
              />
            ) : null}
            <Text
              variant="sm"
              css={{ color: "$on_surface_high", fontWeight: "$semiBold" }}
            >
              {label || "View Recording"}
            </Text>
            {!disabled && !isDownloadButton ? (
              <ExternalLinkIcon
                height={14}
                width={14}
                style={{ color: "inherit", position: "relative", top: "-1px" }}
              />
            ) : (
              <></>
            )}
          </Button>
        </Text>
      </a>
    </Flex>
  );
};
