import React from "react";
import Skeleton from "react-loading-skeleton";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import EmptyStateForPeerCharts from "src/modules/peerInsights/components/EmptyStateForPeerCharts";
import { ApiCallStateValues } from "src/types/common";
import { AlertTriangleIcon, SupportIcon } from "@100mslive/react-icons";
import { Button, CSS, Flex, Text } from "@100mslive/roomkit-react";

type Props = {
  status: ApiCallStateValues;
  children: JSX.Element;
  title: string;
  header?: JSX.Element;
  isDataEmpty: boolean;
  emptyStateMessage?: string;
  emptyStateCSS?: CSS;
  childWrapperCSS?: CSS;
  css?: CSS;
};

function ChartLoaderLayout({
  status,
  header,
  isDataEmpty,
  children,
  title,
  emptyStateMessage,
  emptyStateCSS,
  childWrapperCSS,
  css,
}: Props) {
  return (
    <Flex css={{ w: "100%", py: "$10", ...css }}>
      <Flex
        direction="column"
        css={{
          w: "100%",
          border: "$space$px solid $border_default",
          r: "$1",
        }}
      >
        <Flex
          justify="between"
          align="center"
          css={{
            cursor: "pointer",
            px: "$10",
            py: "$9",
          }}
        >
          {status === API_CALL_STATE.IN_PROGRESS ? (
            <Skeleton width={160} height="100%" />
          ) : header ? (
            header
          ) : (
            <Text
              variant="sub2"
              css={{
                fontWeight: "$semiBold",
              }}
            >
              {title}
            </Text>
          )}
        </Flex>{" "}
        <Flex
          css={{
            ...childWrapperCSS,
            overflow: "hidden",
            transition:
              "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
          }}
          direction="column"
        >
          {status === API_CALL_STATE.IN_PROGRESS ? (
            <Skeleton width="100%" height={240} />
          ) : status === API_CALL_STATE.FAILED ? (
            <Flex
              css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              justify="center"
              align="center"
              direction="column"
            >
              <Flex css={{ c: "$alert_error_default" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column" align="center">
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    c: "$on_surface_medium",
                    w: "20rem",
                  }}
                >
                  We&apos;re having trouble displaying the data. Please refresh
                  or try again later. For further assistance, contact support.
                </Text>
              </Flex>

              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          ) : isDataEmpty ? (
            <EmptyStateForPeerCharts
              title={`No ${title} Data`}
              css={{ ...emptyStateCSS }}
              subtitle={
                emptyStateMessage
                  ? emptyStateMessage
                  : "Data unavailable. Begin utilizing 100ms and conduct some sessions to generate and display data here."
              }
            />
          ) : (
            <Flex
              direction="column"
              css={{
                borderTop: "$space$px solid $border_default",
                py: "$8",
              }}
            >
              {children}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ChartLoaderLayout;
