import React from "react";
import Credential from "src/components/Common/Credential";
import { API_CALL_STATE } from "src/constants";
import { roomRTMPStreamKeyType } from "src/types/roomCodeTypes";
import { AlertTriangleIcon } from "@100mslive/react-icons";
import { Button, Flex, Loading, Text } from "@100mslive/roomkit-react";

type Props = {
  policyId: string;
  roomId: string;
  isOnboarding: boolean;
  templateStatus: string;
  fetchRTMPStatus: string;
  isHLSEnabled: boolean;
  roomStreamDetails: Record<string, roomRTMPStreamKeyType>;
  createRTMPStream: (roomId: string) => void;
  createRTMPStreamStatus: string;
};
export default function StreamDetailsForm({
  policyId,
  roomId,
  fetchRTMPStatus,
  createRTMPStream,
  createRTMPStreamStatus,
  templateStatus,
  isHLSEnabled,
  roomStreamDetails,
}: Props) {
  const CredentialsCSS = {
    css: { flexDirection: "column", maxWidth: "400px", mr: "$px" },
    inputCss: {
      border: "1px solid $border_bright",
      backgroundColor: "$surface_brighter",
      borderRadius: "$0",
      mr: "$px",
    },
    labelCss: {
      fontWeight: "$semiBold",
      color: "$on_surface_medium",
      fontSize: "$xs",
      marginBottom: "$xs",
    },
  };
  return (
    <Flex direction="column" align="start">
      {templateStatus === API_CALL_STATE.IN_PROGRESS ||
      fetchRTMPStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ w: "100%", h: "100%" }} justify="center" align="center">
          <Loading size={72} />
        </Flex>
      ) : (
        <>
          <Flex direction="column">
            {!isHLSEnabled ? (
              <Flex css={{ mb: "$10", c: "$alert_error_default" }}>
                <AlertTriangleIcon height={32} width={32} />
              </Flex>
            ) : null}
            <Text variant="sub1" css={{ c: "$on_surface_high" }}>
              {isHLSEnabled
                ? "Input Stream Details"
                : `Enable Live Streaming (HLS)`}
            </Text>
            {isHLSEnabled ? (
              <Text
                variant="body2"
                css={{
                  fontWeight: "$regular",
                  c: "$on_surface_medium",
                  mt: "$4",
                }}
              >
                Copy and paste the following details in your broadcasting
                software and start your stream to go live on 100ms. Read{" "}
                <a
                  href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/live-streaming-rtmp-ingestion`}
                >
                  <Text
                    as="span"
                    variant="body2"
                    css={{
                      c: "$primary_default",
                      "&:hover": { c: "$primary_bright" },
                    }}
                  >
                    this guide
                  </Text>
                </a>{" "}
                on how to configure OBS studio for streaming.
              </Text>
            ) : (
              <Text
                variant="body2"
                css={{
                  fontWeight: "$regular",
                  c: "$on_surface_medium",
                  mt: "$4",
                }}
              >
                The stream details used for live streaming using RTMP Ingest
                will be available here once Live streaming (HLS) is enabled for
                this template.
              </Text>
            )}
            {isHLSEnabled ? (
              <Flex css={{ mt: "$10", gap: "$10" }}>
                {roomStreamDetails?.[roomId]?.key ? (
                  <Credential
                    {...CredentialsCSS}
                    outlined
                    readOnly
                    label="RTMP Stream Key"
                    value={roomStreamDetails?.[roomId]?.key}
                  />
                ) : (
                  <Flex direction="column" css={{ w: "50%" }}>
                    <Text
                      variant="caption"
                      css={{
                        c: "$on_surface_medium",
                        h: "$10",
                        fontWeight: "$semiBold",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      RTMP Stream Key
                    </Text>
                    <Button
                      variant="primary"
                      onClick={async () => await createRTMPStream(roomId)}
                      loading={
                        createRTMPStreamStatus === API_CALL_STATE.IN_PROGRESS
                      }
                      css={{ r: "$0", mt: "$4", w: "max-content" }}
                    >
                      Generate
                    </Button>
                  </Flex>
                )}
                <Credential
                  {...CredentialsCSS}
                  outlined
                  readOnly
                  disabled={fetchRTMPStatus !== API_CALL_STATE.DONE}
                  label="RTMP URL"
                  value={
                    roomStreamDetails?.[roomId]?.url
                      ? roomStreamDetails?.[roomId]?.url
                      : "-"
                  }
                  showClipboard={!!roomStreamDetails?.[roomId]?.url}
                />
              </Flex>
            ) : (
              <a href={`/templates/${policyId}?tab=live-streaming`}>
                <Button variant="primary" css={{ r: "$0", mt: "$10" }}>
                  Configure Template {`->`}
                </Button>
              </a>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}
