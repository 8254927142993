import React from "react";
import { isEmpty } from "lodash";
import { peerAnalyticsPublishType } from "src/types/analytics";
import { policyTemplateType } from "src/types/policyTypes";
import { Flex, Text } from "@100mslive/roomkit-react";
import { videoLayerKeyAsPerLayers, videoSimulcastMap } from "../../constants";

type Props = {
  policy: policyTemplateType;
  role: string;
  publishData: peerAnalyticsPublishType;
};

function TrackMessage({ text, val }: { text: string; val: string }) {
  return (
    <Flex
      css={{
        px: "$8",
        mb: "$6",
        textAlign: "left",
      }}
      direction="column"
    >
      <Text variant="caption" css={{ c: "$on_surface_low" }}>
        {text}
      </Text>
      <Text variant="caption" css={{ c: "$on_surface_medium", mt: "$1" }}>
        {`${val}`}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function PublisherFrameRateMetrics({
  policy,
  publishData,
  role,
}: Props) {
  const videoLayersArray = videoLayerKeyAsPerLayers(publishData?.video);
  const screenLayer = publishData?.screen || [];

  const calulateAverageFrameRate = (
    data:
      | {
          timestamp: string;
          fps: number;
        }[]
      | undefined
  ) => {
    if (isEmpty(data) || data === undefined) {
      return 0;
    }
    //calculate average bitrate using difference between timestamps and find the total val bitrate
    let totalFPS = 0;
    let totalDuration = 0;
    for (let i = 0; i < data.length - 1; i++) {
      const diff =
        (new Date(data[i + 1].timestamp).getTime() -
          new Date(data[i].timestamp).getTime()) /
        1000;
      if (Number.isFinite(data?.[i]?.fps)) {
        totalFPS += data[i].fps * diff;
      }
      totalDuration += diff;
    }

    if (totalDuration === 0) {
      return Number.isFinite(data?.[0]?.fps) ? data?.[0]?.fps : 0;
    }

    return totalFPS / totalDuration;
  };

  const getTrackConfigFromPolicy = (key: string) => {
    if (key.toLowerCase().includes("screen")) {
      return policy?.roles?.[role]?.publishParams?.screen?.frameRate || 0;
    }
    if (key.toLowerCase().includes("video")) {
      if (videoLayersArray.length > 1) {
        const simulcastKey =
          videoSimulcastMap[key as keyof typeof videoSimulcastMap];
        const layers = (
          (policy?.roles?.[role]?.publishParams?.simulcast?.video?.layers ||
            []) as []
        )?.filter((layer: any) => layer.rid === simulcastKey);

        return (layers?.[0] as { maxFramerate: number })?.maxFramerate || 0;
      } else {
        return policy?.roles?.[role]?.publishParams?.video?.frameRate || 0;
      }
    }
    return 0;
  };
  return (
    <Flex
      css={{
        borderTop: "$space$px solid $border_default",
        borderLeft: "$space$px solid $border_default",
        textAlign: "center",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      <Flex css={{ borderBottom: "$space$px solid $border_default" }}>
        <Text
          variant="caption"
          css={{
            c: "$on_surface_low",
            py: "$4",
            px: "$8",
            textAlign: "left",
            width: "50%",
            borderRight: "$space$px solid $border_default",
          }}
        >
          Configured FPS
        </Text>

        <Text
          variant="caption"
          css={{
            c: "$on_surface_low",
            textAlign: "left",
            py: "$4",
            px: "$8",
            width: "50%",
          }}
        >
          Average FPS
        </Text>
      </Flex>
      <Flex css={{ w: "100%", h: "100%" }}>
        <Flex
          direction="column"
          css={{
            w: "50%",
            pt: "$4",
            minHeight: "100%",
            borderRight: "$space$px solid $border_default",
          }}
        >
          {isEmpty(videoLayersArray) ? (
            <></>
          ) : (
            <>
              {" "}
              {videoLayersArray.map(layer => {
                return (
                  <TrackMessage
                    key={layer}
                    text={layer}
                    val={getTrackConfigFromPolicy(layer).toFixed(0)}
                  />
                );
              })}
            </>
          )}

          {isEmpty(screenLayer) ? (
            <></>
          ) : (
            <TrackMessage
              text="Screen Share"
              val={getTrackConfigFromPolicy("screen").toFixed(0)}
            />
          )}
        </Flex>
        <Flex
          css={{
            w: "50%",
            minHeight: "100%",
            pt: "$4",
          }}
          direction="column"
        >
          {" "}
          {isEmpty(videoLayersArray) ? (
            <></>
          ) : (
            <>
              {" "}
              {videoLayersArray.map(layer => {
                return (
                  <TrackMessage
                    text={layer}
                    key={layer}
                    val={calulateAverageFrameRate(
                      (publishData?.video?.[
                        videoSimulcastMap[
                          layer as keyof typeof videoSimulcastMap
                        ]
                      ] || []) as {
                        timestamp: string;
                        fps: number;
                      }[]
                    ).toFixed(0)}
                  />
                );
              })}
            </>
          )}
          {isEmpty(screenLayer) ? (
            <></>
          ) : (
            <TrackMessage
              text="Screen Share"
              val={calulateAverageFrameRate(
                (publishData?.screen || []) as {
                  timestamp: string;
                  fps: number;
                }[]
              ).toFixed(0)}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
