import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { RootState } from "src/store/reducers";
import { peerIceCandidates } from "src/types/analytics";
import {
  AlertTriangleIcon,
  InfoIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/roomkit-react";
import PeerICECandidatesWebrtcDetails from "../components/PeerICECandidatesWebrtcDetails";
import "src/components/Common/tooltipReactstrap.css";
type Props = {
  peerId: string;
};

const infoFlexCss = {
  borderBottomRightRadius: "$1",
  borderBottomLeftRadius: "$1",
  flex: "1 1 0",
  flexWrap: "wrap",
  gap: "$10",
  w: "100%",
  rowGap: "$4",
  borderBottom: "$space$px solid $border_default",
  borderLeft: "$space$px solid $border_default",
  borderRight: "$space$px solid $border_default",
  borderTop: "none",
};
// eslint-disable-next-line complexity, no-empty-pattern
const PeerIceCandidates = ({}: Props) => {
  const fetchPeerAnalyticsPeerWebrtcStatsStatus = useSelector(
    (state: RootState) =>
      state.roomDebugger.fetchPeerAnalyticsPeerWebrtcStatsStatus
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const iceCandidates = useSelector(
    (state: RootState) => state.roomDebugger.iceCandidates
  );

  return (
    <>
      {fetchPeerAnalyticsPeerWebrtcStatsStatus !== API_CALL_STATE.FAILED ? (
        <Flex css={{ w: "100%", pb: "$10" }}>
          <Flex css={{ ...infoFlexCss, py: "$8", position: "relative" }}>
            <Flex gap={2} css={{ w: "100%" }}>
              {fetchPeerAnalyticsPeerWebrtcStatsStatus ===
              API_CALL_STATE.IN_PROGRESS ? (
                Array(2)
                  .fill(2)
                  .map((_x, idx) => {
                    return (
                      <Flex
                        key={idx + _x}
                        justify="center"
                        align="center"
                        css={{ w: "50%", px: "$md" }}
                      >
                        <Skeleton containerClassName="flex-1" height={80} />
                      </Flex>
                    );
                  })
              ) : (
                <Flex
                  css={{
                    gap: "$16",
                    ml: "$10",
                    w: "100%",
                  }}
                >
                  {fetchPeerAnalyticsPeerWebrtcStatsStatus ===
                  API_CALL_STATE.DONE ? (
                    <PeerICECandidatesWebrtcDetails
                      peerWebrtcData={
                        (iceCandidates || {}) as peerIceCandidates
                      }
                    />
                  ) : (
                    <>
                      {Array(2)
                        .fill(1)
                        .map(_ => {
                          return <Skeleton width={160} height={44} />;
                        })}
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex css={{ w: "100%", pb: "$10", textAlign: "center" }}>
          <Flex css={{ ...infoFlexCss, py: "$8", position: "relative" }}>
            <Flex align="center">
              <Text variant="sub2" css={{ fontWeight: 600, ml: "$10" }}>
                ICE Candidate Types
              </Text>
              <Flex css={{ ml: "$4", c: "$textMedEmp" }}>
                <SmallIcon>
                  <InfoIcon id="mos-tooltip" />
                </SmallIcon>
              </Flex>
              <Tooltip
                isOpen={tooltipOpen}
                toggle={toggle}
                placement="top"
                target="mos-tooltip"
                align="center"
                className="mytooltip-xl"
              >
                <Text variant="caption">
                  This score is available only for video calls currently. This
                  is calculated using the peer's download network parameters
                  namely - packet loss, jitter and latency. A value of 5
                  represents excellent connection and 1 represents very bad
                  connection
                </Text>
              </Tooltip>
            </Flex>

            <Flex
              css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              direction="column"
              justify="center"
              align="center"
            >
              <Flex css={{ c: "$error" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column" css={{ textAlign: "center" }}>
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{ fontWeight: "$regular", c: "$textMedEmp", w: "20rem" }}
                >
                  We couldn&apos;t load details for this chart. This feature is
                  currently in alpha and there are known issues we&apos;re
                  working on.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default PeerIceCandidates;
