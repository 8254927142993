import React from "react";
import * as echarts from "echarts";
import { capitalize, isEmpty } from "lodash";
import { default as EchartsWrapper } from "src/components/Echarts";
import { Flex } from "@100mslive/roomkit-react";
import {
  WORKSPACE_INSIGHTS_COLOR_CODES,
  WORKSPACE_PEER_PLATFORM_LIST,
} from "../../constants";

type echartsOptions = echarts.EChartsOption;
export const ActivePeersChartBreakdown = ({
  data,
  loading,
  isGroupByRole,
}: {
  data: Record<string, Record<string, number>>;
  loading: boolean;
  isGroupByRole: boolean;
}) => {
  if (isEmpty(data)) {
    return <></>;
  }

  const getDataConfig = (data: Record<string, Record<string, number>>) => {
    if (isEmpty(data)) {
      return {};
    }
    const data_map = {} as Record<
      string,
      { name: string; count: number; color: string }
    >;
    const objKeyArr = Object.keys(data).sort();

    for (let index = 0; index < objKeyArr.length; index++) {
      const item = objKeyArr[index];
      const obj = data[item];
      const rem = index % 10;
      const lineColorHEX = WORKSPACE_INSIGHTS_COLOR_CODES[rem];
      const name = isGroupByRole
        ? capitalize(item)
        : WORKSPACE_PEER_PLATFORM_LIST[
            item as keyof typeof WORKSPACE_PEER_PLATFORM_LIST
          ].name;
      for (const key in obj) {
        if (data_map[item]) {
          data_map[item].count += obj[key as keyof typeof obj];
        } else {
          data_map[item] = {
            name: name,
            count: obj[key as keyof typeof obj],
            color: lineColorHEX,
          };
        }
      }
    }
    return data_map;
  };

  const config = getDataConfig(data);
  const eventLogChartoptions: echartsOptions = {
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: "item", // Trigger tooltip on item (icon) hover
      backgroundColor: "#1E232A",
      borderRadius: 4,
      padding: 4,
      borderColor: "#1E232A",
      textStyle: {
        color: "#F5F9FF",
      },
      show: true,
    },

    grid: {
      top: "30%",
      left: "10%",
      right: "5%",
    },
    series: {
      type: "pie",
      radius: [60, 100],
      center: ["50%", "50%"],

      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: "bold",
          color: "#F5F9FF",
          formatter: "{b}",
        },
      },
      label: {
        show: false,
        position: "center",
      },
      labelLine: {
        show: false,
      },
      itemStyle: {
        borderRadius: 5,
      },
      data: Object.keys(config).map(key => {
        return {
          name: config[key].name,
          value: config[key].count,
          itemStyle: { color: config[key].color },
        };
      }),
    },
  };

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <>
        {/* @ts-ignore */}
        <EchartsWrapper
          options={{ ...eventLogChartoptions }}
          loading={loading}
          className="active-peers-breakdown-chart"
          style={{ height: "240px", width: "auto" }}
        />
      </>
    </Flex>
  );
};
