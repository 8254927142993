/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isFunction } from "lodash";
import { fetchTemplatesData } from "src/actions/RolesActions";
import GridPerspectiveFloor from "src/components/Common/GridPerspectiveFloor";
import JoinRoomForm from "src/components/JoinRoomFormV2";
import { templateTypes } from "src/constants";
import { AppAnalytics, flattenObject, history, toTitleCase } from "src/helpers";
import {
  createTemplateFromStoreV2,
  setAppDetailsInStore,
  setIsRoomNameValid,
  setLargeRoomBoolInStore,
  setRoleLayoutsInStore,
  setRoomNameInStore,
  setStepAnswers,
  setTemplateIdAndPTypeStore,
  setTemplateIdInStore,
  setTemplateInStore,
} from "src/store/createTemplate/actions";
import { RootState } from "src/store/reducers";
import { fetchGeoInfo } from "src/store/userInfo/actions";
import { policyType } from "src/types/policyTypes";
import {
  currentUser,
  getCurrentWorkspaceID,
  saveCurrentUser,
  signedUpUser,
} from "src/utils";
import { DoorIcon, LayoutIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/roomkit-react";
import { fetchPublicTemplates } from "../../../actions/CreateAppActions";
import UseCaseForm from "../components/Forms/UseCaseForm";
import UseCaseQuestionForm, {
  LiveStreamingAddOnForm,
  SetTemplateStepsConfigurationForm,
} from "../components/Forms/UseCaseQuestionForm";
import FormContent from "../components/layout/FormContent";
import FormSidebar from "../components/layout/FormSidebar";
import { isUseCaseCMSTemplate } from "../helpers";
import masterTemplateToAnswerConfig from "../helpers/answerToTemplate";
import useOnboarding from "../hooks/useOnboarding";
import { ConfigMap } from "../interface";
// Constants
export const steps = {
  CONFIGURE_ROOM: { key: "CONFIGURE_ROOM", name: "Pick your use-case" },
  CONFIGURE_USE_CASE: { key: "CONFIGURE_USE_CASE", name: "Configure" },
  SELECT_ADD_ON: { key: "SELECT_ADD_ON", name: "Select Add-ons" },
  JOIN_ROOM: { key: "JOIN_ROOM", name: "Experience" },
};

const iconStyle = { color: "inherit", height: "32px", width: "32px" };
const OnboardingFlowWithLayout = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<string>(
    steps.CONFIGURE_ROOM.key
  );

  const publicTemplates = useSelector(
    (state: RootState) => state.createApp.publicTemplates
  );
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );
  const workspaces = useSelector(
    (state: RootState) => state.workspace.workspaces
  );

  const policyType = useSelector(
    (state: RootState) => state.createTemplate.policyType
  );
  const id = useSelector((state: RootState) => state.createTemplate.id);
  const isRoomNameValid = useSelector(
    (state: RootState) => state.createTemplate.isRoomNameValid
  );
  const stepAnswers = useSelector(
    (state: RootState) => state.createTemplate.stepAnswers
  );
  const createdTemplate = useSelector(
    (state: RootState) => state.createTemplate.createdTemplate
  );
  const subdomain = useSelector(
    (state: RootState) => state.createTemplate.subdomain
  );
  const roomId = useSelector((state: RootState) => state.createTemplate.roomId);

  const roomName = useSelector(
    (state: RootState) => state.createTemplate.roomName
  );

  const policy = useSelector((state: RootState) => state.createTemplate.policy);

  const setTemplateFlow = (templateId: null | number) => {
    dispatch(setTemplateIdAndPTypeStore(templateId));
  };

  const region = useSelector((state: RootState) => state.userInfo.region);
  const {
    config,
    roleLayout: cmsRoleLayouts,
    policy: template,
    addOns,
  } = useOnboarding();

  useEffect(() => {
    const user = currentUser();
    dispatch(fetchGeoInfo());
    dispatch(fetchTemplatesData());
    setActiveStep(Object.keys(steps)[0]);
    const firstName =
      toTitleCase(user?.first_name?.split(" ")?.[0]) || crypto.randomUUID();
    dispatch(setRoomNameInStore(`${firstName}'s Room`));
    dispatch(setIsRoomNameValid(true));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEmpty(publicTemplates)) {
      dispatch(fetchPublicTemplates());
    }
  }, [publicTemplates]);

  useEffect(() => {
    if (
      all_templates.length >= 1 &&
      workspaces[getCurrentWorkspaceID()]?.is_admin
    ) {
      const user = currentUser();
      const tempUser = {
        ...user,
        show_onboarding: false,
      };
      saveCurrentUser(tempUser);
    }
  }, [all_templates]);

  useEffect(() => {
    if (typeof id === "number") {
      dispatch(setTemplateInStore({ policy: template, checkPolicyName: true }));
      dispatch(setRoleLayoutsInStore(cmsRoleLayouts));
    }
    // eslint-disable-next-line
  }, [id, template]);

  const stepsConfig: ConfigMap = {
    CONFIGURE_ROOM: {
      id: 0,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Pick your use-case",
          subtitle: "Select your use-case, and we’ll help you configure it",
        },
        innerContent: (
          <UseCaseForm
            publicTemplates={publicTemplates}
            setTemplateFlow={setTemplateFlow}
          />
        ),
        footer: {
          disablePrimary: id === null || !isRoomNameValid || !region,

          primaryCTA: {
            text: "Configure",
            onClick: () => {
              AppAnalytics.track("btn.clicked", {
                btnId: "configure",
                componentId: "guided.onboarding",
                value: "step.1",
                policyType: policyType,
              });

              if (isUseCaseCMSTemplate(policyType)) {
                const configAnswers = config.reduce((acc, c) => {
                  return { ...acc, [c.key]: c.defaultValue };
                }, {});
                const addOnAnswers = addOns.reduce((acc, c) => {
                  return { ...acc, [c.key]: c.defaultValue };
                }, {});

                dispatch(
                  setStepAnswers({
                    [steps.CONFIGURE_USE_CASE.key]: configAnswers,
                    [steps.SELECT_ADD_ON.key]: addOnAnswers,
                  })
                );
                setActiveStep(steps.CONFIGURE_USE_CASE.key);
              } else {
                dispatch(createTemplateFromStoreV2(true));
                setActiveStep(steps.JOIN_ROOM.key);
              }
            },
          },
        },
      },
    },
    CONFIGURE_USE_CASE: {
      id: 1,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Configure",
          subtitle:
            "Select features and configuration you require for your use-case",
        },
        innerContent:
          policyType !== templateTypes.LIVE_STREAM ? (
            <UseCaseQuestionForm
              step={steps.CONFIGURE_USE_CASE.key}
              configs={config}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                dispatch(setStepAnswers({ ...stepAns }))
              }
            />
          ) : (
            <SetTemplateStepsConfigurationForm
              css={{ maxHeight: "456px" }}
              step={steps.CONFIGURE_USE_CASE.key}
              key="CONFIGURE_USE_CASE"
              configs={config}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                dispatch(setStepAnswers({ ...stepAns }))
              }
            />
          ),
        footer: {
          disablePrimary: false,
          text: "Not able to choose? You can always change your configuration later",
          secondaryCTA: {
            text: "Go Back",
            onClick: () => {
              setActiveStep(steps.CONFIGURE_ROOM.key);
              dispatch(setTemplateIdInStore(null));
              dispatch(
                setTemplateInStore({
                  policy: {} as policyType,
                  checkPolicyName: false,
                })
              );
            },
          },
          primaryCTA: {
            text: "Select Add-ons",
            onClick: () => {
              AppAnalytics.track("btn.clicked", {
                btnId: "select.add.ons",
                componentId: "guided.onboarding",
                value: "step.2",
                policyType: policyType,
              });
              setActiveStep(steps.SELECT_ADD_ON.key);
            },
          },
        },
      },
    },

    SELECT_ADD_ON: {
      id: 2,
      content: {
        header: {
          icon: <LayoutIcon style={iconStyle} />,
          title: "Select Add-ons",
          subtitle: "Pick additional features you require for your use-case",
        },
        innerContent:
          policyType !== templateTypes.LIVE_STREAM ? (
            <LiveStreamingAddOnForm
              step={steps.SELECT_ADD_ON.key}
              configs={addOns}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                dispatch(setStepAnswers({ ...stepAns }))
              }
            />
          ) : (
            <SetTemplateStepsConfigurationForm
              css={{ maxHeight: "456px" }}
              key="SELECT_ADD_ON"
              step={steps.SELECT_ADD_ON.key}
              configs={addOns}
              stepAnswers={stepAnswers}
              setStepAnswers={(stepAns: typeof stepAnswers) =>
                dispatch(setStepAnswers({ ...stepAns }))
              }
            />
          ),
        footer: {
          disablePrimary: false,
          text: "Not able to choose? You can always change your configuration later",
          secondaryCTA: {
            text: "Go Back",
            onClick: () => {
              setActiveStep(steps.CONFIGURE_USE_CASE.key);
            },
          },

          primaryCTA: {
            text: "Experience",
            onClick: () => {
              const selectionAnalyticsData = flattenObject({
                selection: stepAnswers,
              });
              AppAnalytics.track("btn.clicked", {
                btnId: "experience",
                componentId: "guided.onboarding.select-add-ons",
                value: "step.3",
                policyType: policyType,
                ...selectionAnalyticsData,
              });

              const tempPolicy = { ...policy };
              const tempRoleLayouts = { ...cmsRoleLayouts };
              if (
                isFunction(
                  masterTemplateToAnswerConfig?.[
                    policyType as keyof typeof masterTemplateToAnswerConfig
                  ]?.function
                )
              ) {
                masterTemplateToAnswerConfig[
                  policyType as keyof typeof masterTemplateToAnswerConfig
                ].function({
                  stepAnswers: stepAnswers,
                  policy: tempPolicy as policyType,
                  roleLayouts: tempRoleLayouts,
                  setPolicy: policy => {
                    dispatch(
                      setTemplateInStore({
                        policy: policy,
                        checkPolicyName: false,
                      })
                    );
                  },
                  setRoleLayouts: roleLayouts => {
                    dispatch(setRoleLayoutsInStore(roleLayouts));
                  },
                  setAppDetails: appDetails => {
                    dispatch(setAppDetailsInStore(appDetails));
                  },
                  setLargeRoom: bool => {
                    dispatch(setLargeRoomBoolInStore(bool));
                  },
                });
              }

              dispatch(createTemplateFromStoreV2(true));
              setActiveStep(steps.JOIN_ROOM.key);
            },
          },
        },
      },
    },

    JOIN_ROOM: {
      id: 3,
      content: {
        header: {
          icon: <DoorIcon style={iconStyle} />,
          title: "Experience",
          subtitle:
            "Select a role and choose the way you want to join your room",
          topRightComp: (
            <Button
              variant="standard"
              css={{ r: "$0" }}
              onClick={() => {
                if (signedUpUser()) {
                  const user = currentUser();
                  const tempUser = {
                    ...user,
                    show_onboarding: !user.show_onboarding,
                  };
                  saveCurrentUser(tempUser);
                }
                AppAnalytics.track("btn.clicked", {
                  btnId: "go.to.dashboard",
                  componentId: "guided.onboarding.customize",
                });
                history.push("/dashboard");
              }}
            >
              <Text variant="sub2">Go to Dashboard</Text>
            </Button>
          ),
        },
        innerContent: (
          <Box
            css={{
              h: "calc(100vh - 380px)",
              overflowY: "auto",
              w: "100%",
              maxHeight: "432px",
            }}
          >
            <JoinRoomForm
              roomId={roomId}
              componentId="guided.onboarding"
              // @ts-ignore FIXME: fix the code flow for created template
              subdomain={createdTemplate.subdomain}
              // @ts-ignore FIXME: fix the code flow for created template
              templateId={createdTemplate.id}
              fetchTemplates={true}
            />
          </Box>
        ),
      },
    },
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="start"
      css={{
        mx: "auto",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Flex
        css={{
          position: "fixed",
          w: "41rem",
          h: "41rem",
          r: "41rem",
          left: "-10%",
          top: "-70%",
          opacity: "0.6",
          background: "rgba(38, 114, 237, 0.24)",
          filter: "blur(250px)",
          zIndex: "0",
        }}
      />
      <Flex
        css={{
          position: "fixed",
          w: "20.25rem",
          h: "20.25rem",
          r: "20.25rem",
          right: "-30%",
          bottom: "20%",
          opacity: "0.3",
          background: "#26BDED",
          filter: "blur(300px)",
          zIndex: "0",
        }}
      />
      <Flex css={{ position: "fixed", w: "100%", height: "100%", bottom: 0 }}>
        <GridPerspectiveFloor />
      </Flex>
      <Flex direction="column">
        <Flex justify="start" direction="column">
          <Text variant="h4" css={{ color: "$on_surface_high" }}>
            Welcome aboard!
          </Text>

          <Text css={{ color: "$on_surface_medium", mt: "$xs" }}>
            Get started with live video by creating a room
          </Text>
        </Flex>
        <Flex align="start" css={{ my: "$16", gap: "$16" }}>
          <FormSidebar
            steps={steps}
            activeStepIndex={stepsConfig?.[activeStep]?.id}
            infoTitle={stepsConfig[activeStep]?.infoTitle}
            infoBody={stepsConfig[activeStep]?.infoBody}
            infoLink={stepsConfig[activeStep]?.infoLink}
            roomName={roomName}
            templateName={policy.name}
            subdomain={subdomain}
          />
          <FormContent
            {...stepsConfig[activeStep]?.content}
            activeStep={activeStep}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OnboardingFlowWithLayout;
