import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { convertSecondsToHumanReadable } from "src/helpers/utils";
import {
  peerMosScoreDataType,
  peerQualityIssueType,
} from "src/types/analytics";
import { InfoIcon, ReportIcon, ShieldIcon } from "@100mslive/react-icons";
import { Box, Flex, Text, Tooltip } from "@100mslive/roomkit-react";
import "moment-duration-format";
type Props = {
  issuesData: peerQualityIssueType;
  mosScoreData: peerMosScoreDataType;
  issuesLoading: boolean;
};
const peerQualityIssues = {
  publisher_cpu: {
    name: "Publisher CPU Limitation",
    tooltip: "Issues due to publishing peer's device cpu capacity",
  },
  publisher_bitrate: {
    name: "Publisher Network Issues",
    tooltip: "Issues due to upload network quality",
  },
  subscriber_network: {
    name: "Subscriber Network Issues",
    tooltip: "Issues due to downlink network quality",
  },
};

export default function MOSInformation({
  issuesData,
  mosScoreData,
  issuesLoading,
}: Props) {
  const noOfIssues = useMemo(() => {
    // if key at issues data is positive, then it is an issue
    return Object.keys(issuesData).filter(
      key => issuesData?.[key as keyof typeof issuesData]
    ).length;
  }, [issuesData]);
  const avgVal = useMemo(() => {
    if (!mosScoreData?.mos_score?.length) {
      return 0;
    }
    const arrLen = mosScoreData?.mos_score?.length || 1;
    const weightedVal = mosScoreData?.mos_score?.reduce((acc, curr) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_key, val] = curr.value;
      if (val === "-") {
        return acc;
      }
      if (typeof val === "number" && val < 0) {
        return acc;
      } else {
        return acc + Number(val);
      }
    }, 0);
    return (Number(weightedVal) / arrLen).toFixed(2);
  }, [mosScoreData]);
  const avgBg = useMemo(() => {
    const avg = Number(avgVal);

    if (avg < 1) {
      return "#CB525E";
    }
    if (avg < 3) {
      return "#FF8000";
    }
    if (avg < 4) {
      return "#EDC602";
    } else {
      return "#36B580";
    }
  }, [avgVal]);

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <Flex css={{ w: "100%" }}>
        <Flex direction="column">
          <Flex align="center">
            <Text variant="caption" css={{ c: "$on_surface_medium", mr: "$4" }}>
              Average CQS
            </Text>
            <Tooltip title="The average CQS (Call Quality Score) is calculated based on the MOS score of the subscriber">
              <Flex
                css={{ c: "$on_surface_medium", cursor: "pointer" }}
                align="center"
              >
                <InfoIcon width={12} height={12} />
              </Flex>
            </Tooltip>
          </Flex>

          <Flex css={{ gap: "$2" }} align="center">
            <Box css={{ bg: avgBg, w: "8px", h: "8px", r: "$round" }} />
            <Text variant="body2">{avgVal ? avgVal : "NA"}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        css={{
          w: "100%",
          h: "$space$px",
          bg: "$border_default",
          my: "$6",
        }}
      />

      <Flex align="center" css={{ w: "100%" }}>
        <Box css={{ c: "$on_surface_low", mr: "$4" }}>
          <SmallIcon>
            <ReportIcon />
          </SmallIcon>
        </Box>

        <Flex justify="between" align="start" css={{ w: "100%" }}>
          <Flex direction="column">
            <Text variant="caption">Quality Issues</Text>
            <Text
              css={{
                c: "$on_surface_medium",
                fontWeight: "$regular",
                mt: "$2",
              }}
              variant="caption"
            >
              {noOfIssues ? `${noOfIssues} Issues` : "No Issues"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {issuesLoading ? (
        <Flex
          direction="column"
          css={{
            rowGap: "$md",
            minHeight: "150px",
            w: "100%",
            flex: "1 1 0",
          }}
          justify="center"
          align="center"
        >
          <Skeleton containerClassName="flex-1 w-full" height={150} />
        </Flex>
      ) : noOfIssues ? (
        <Flex
          direction="column"
          css={{ pr: "$1", overflowY: "scroll", h: "150px" }}
        >
          {Object.keys(issuesData).map(issue => {
            if (!issuesData[issue as keyof typeof issuesData]) {
              return null;
            }
            return (
              <Flex
                direction="column"
                css={{
                  py: "$6",
                  borderBottom: "$space$px solid $border_default",
                  "&:last-child": { border: "none !important" },
                }}
              >
                <Flex direction="row" align="center" css={{ gap: "$space$4" }}>
                  <Text variant="caption">
                    {
                      peerQualityIssues[issue as keyof typeof peerQualityIssues]
                        .name
                    }
                  </Text>

                  <Tooltip
                    title={
                      peerQualityIssues[issue as keyof typeof peerQualityIssues]
                        .tooltip
                    }
                  >
                    <Flex
                      css={{ c: "$on_surface_medium", cursor: "pointer" }}
                      align="center"
                    >
                      <InfoIcon width={12} height={12} />
                    </Flex>
                  </Tooltip>
                </Flex>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    c: "$on_surface_medium",
                    mt: "$4",
                  }}
                >
                  {`for ${convertSecondsToHumanReadable(
                    issuesData?.[issue as keyof typeof issuesData] || 0
                  )} in total`}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Flex
          direction="column"
          css={{
            rowGap: "$md",
            minHeight: "150px",
            w: "100%",
            textAlign: "center",
          }}
          justify="center"
          align="center"
        >
          <Flex css={{ c: "$on_surface_low" }}>
            <ShieldIcon width={40} height={40} />
          </Flex>
          <Flex direction="column">
            <Text variant="sub2" css={{ mb: "$2" }}>
              No Issues
            </Text>
            <Text
              variant="caption"
              css={{
                fontWeight: "$regular",
                c: "$on_surface_medium",
                maxWidth: "20rem",
              }}
            >
              This peer had no quality issues throughout the session.
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
