import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { API_CALL_STATE } from "src/constants";
import { fetchWorkspaceSessionDetails } from "src/store/insights/actions";
import { RootState } from "src/store/reducers";
import { InfoIcon, StatsIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text, Tooltip } from "@100mslive/roomkit-react";
import { ActiveSessionsChart } from "../components/charts/activeSessionsChart";
import { ActiveSessionsDurationChart } from "../components/charts/activeSessionsDurationChart";
import { SessionsBreakdownChart } from "../components/charts/sessionsBreakdownChart";
import { SessionsBreakdownBriefChart } from "../components/charts/sessionsBreakdownChartBrief";
import TitleWithTrend from "../components/common/TitleWithTrend";
import { convertDateToDiffDays } from "../helpers";
import ChartLoaderLayout from "../layouts/ChartLoaderLayout";

export const WorkspaceSessionsInsights = () => {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    filter,
    fetchWorkspaceSessionInsightsStatus,
    workspaceSessionInsightsDetails,
    workspaceSessionInsightsBreakdown,
    workspaceSessionInsightsTrends,
  } = useSelector((state: RootState) => state.insights);
  const range = convertDateToDiffDays({
    startDate: startDate || (new Date() as Date),
    endDate: endDate || (new Date() as Date),
  });
  const [showDetails, setShowDetails] = useState(false);
  const sessionKeys = {
    activeSessions: {
      name: "Total Sessions",
      key: "activeSessions",
      Component: ActiveSessionsChart,
      Header: () => (
        <TitleWithTrend
          title="Total Sessions"
          tooltip={`The following number represents the total sessions from the last ${range}, along with a percentage comparison to the previous period. The graph shows the ${filter} distribution and trend of these sessions.`}
          total={workspaceSessionInsightsTrends?.sessions_count_curr_total || 0}
          trend={
            workspaceSessionInsightsTrends?.sessions_count_percent_change || 0
          }
          makeTotalOpaque={
            fetchWorkspaceSessionInsightsStatus === API_CALL_STATE.DONE
          }
        />
      ),
    },
    activeSessionsDuration: {
      name: `Average Session Duration`,
      key: "activeSessionsDuration",

      Component: ActiveSessionsDurationChart,
      Header: () => (
        <TitleWithTrend
          totalLabel="mins"
          title="Average Session Duration"
          tooltip={`The following number shows the average session duration from the last ${range}, with a percentage comparison to the previous period. The graph displays the distribution of average durations on a ${filter} basis.`}
          total={
            workspaceSessionInsightsTrends?.sessions_duration_curr_avg || 0
          }
          trend={
            workspaceSessionInsightsTrends?.sessions_duration_percent_change ||
            0
          }
          makeTotalOpaque={
            fetchWorkspaceSessionInsightsStatus === API_CALL_STATE.DONE
          }
        />
      ),
    },
  };

  useEffect(() => {
    if (startDate && endDate && filter) {
      dispatch(fetchWorkspaceSessionDetails());
    }
  }, [dispatch, endDate, filter, startDate]);

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <Box
        css={{
          display: "grid",
          w: "100%",
          gridTemplateColumns: "repeat(3, 1fr)",
          columnGap: "$10",
        }}
      >
        {Object.keys(sessionKeys).map(key => {
          const config = sessionKeys[key as keyof typeof sessionKeys];
          return (
            <ChartLoaderLayout
              key={key}
              header={<config.Header />}
              status={fetchWorkspaceSessionInsightsStatus}
              title={sessionKeys[key as keyof typeof sessionKeys].name}
              isDataEmpty={isEmpty(workspaceSessionInsightsDetails)}
            >
              <config.Component
                data={workspaceSessionInsightsDetails}
                loading={
                  fetchWorkspaceSessionInsightsStatus ===
                  API_CALL_STATE.IN_PROGRESS
                }
                filter={filter}
              />
            </ChartLoaderLayout>
          );
        })}
        <ChartLoaderLayout
          status={fetchWorkspaceSessionInsightsStatus}
          header={
            <Flex align="center" justify="between" css={{ w: "100%" }}>
              <Flex align="center">
                <Text
                  variant="sub2"
                  css={{
                    fontWeight: "$semiBold",
                    mr: "$4",
                  }}
                >
                  Session Duration Segmented Breakdown
                </Text>
                <Tooltip
                  title={`This is a bucketed distribution of duration for all the sessions that happened in the last ${range.match(
                    /\d+/
                  )} days.`}
                  side="top"
                  align="center"
                  boxCss={{ w: "$80", zIndex: 10, r: "$1" }}
                >
                  <Flex
                    css={{
                      cursor: "pointer",
                      "&:hover": { c: "$on_surface_low" },
                    }}
                  >
                    <SmallIcon>
                      <InfoIcon />
                    </SmallIcon>
                  </Flex>
                </Tooltip>
              </Flex>
              <Tooltip
                title="Click to see Detailed Breakdown"
                align="center"
                side="top"
              >
                <Button
                  variant="standard"
                  outlined={!showDetails}
                  css={{ p: "$1 $2", r: "$0" }}
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <SmallIcon>
                    <StatsIcon />
                  </SmallIcon>
                </Button>
              </Tooltip>
            </Flex>
          }
          title="Session Duration Segmented Breakdown"
          isDataEmpty={isEmpty(workspaceSessionInsightsDetails)}
        >
          <SessionsBreakdownBriefChart
            data={workspaceSessionInsightsBreakdown}
            loading={
              fetchWorkspaceSessionInsightsStatus === API_CALL_STATE.IN_PROGRESS
            }
          />
        </ChartLoaderLayout>
      </Box>

      <Flex
        css={{
          w: "100%",
          display: showDetails ? "block" : "none",
          maxHeight: showDetails ? "100%" : "0px",
          transition: "maxHeight 0.5s ease-in-out",
        }}
      >
        <ChartLoaderLayout
          status={fetchWorkspaceSessionInsightsStatus}
          title="Breakdown of Session Duration"
          isDataEmpty={isEmpty(workspaceSessionInsightsBreakdown)}
          header={
            <TitleWithTrend
              title="Session Duration Distribution"
              tooltip={`This graph shows the number of sessions against their duration for the last ${range} days, detailing how many sessions occurred for each specific time duration.`}
              total={0}
              trend={0}
              showTotal={false}
            />
          }
        >
          <SessionsBreakdownChart
            data={workspaceSessionInsightsBreakdown}
            loading={
              fetchWorkspaceSessionInsightsStatus === API_CALL_STATE.IN_PROGRESS
            }
          />
        </ChartLoaderLayout>
      </Flex>
    </Flex>
  );
};

export default WorkspaceSessionsInsights;
